import AxiosInstance from '../axiosInstance';
import ServiceRequestCommand from '../models/Dto/serviceRequestCommand';
import SrCommandResults from '../models/SrCommandResults';
import SrItemRepository from '../repositories/SrItemRepository';

export default class CreateSrServices {
    private readonly axios = new AxiosInstance();
    private readonly srItemRepository = new SrItemRepository();

    public async createServiceRequest(srInfo: ServiceRequestCommand): Promise<SrCommandResults> {
        const response = await this.srItemRepository.createSr(srInfo);

        return response;

        // to do: cache the SR number and SR item number to add customer details and offence details in the next step
    }
}
