export enum DogColours {
    TAN = 'TAN',
    BRO = 'BROWN',
    BLK = 'BLACK',
    BLU = 'BLUE',
    GRY = 'GREY',
    TRI = 'TRI-COLOUR',
    GLD = 'GOLD',
    BDL = 'BRINDLE',
    RED = 'RED',
    WHI = 'WHITE',
    ONG = 'ORANGE',
}
