import AxiosInstance from '../axiosInstance';
import ISrItemRepository from './Impl/ISrItemRepository';
import ServiceRequestCommand from '../models/Dto/serviceRequestCommand';
import SrCommandResults, { SrItemUpdateCommandResult, SrItemCommandResults } from '../models/SrCommandResults';
import { ResultStatus } from '../models/Dto/resultStatus';
import ServiceRequestItemResults from '../models/Dto/serviceRequestItemResults';
import { SearchSrItemConditions } from '../models/searchConditionsModel';
import SrItemCommand from '../models/Dto/srItemCommand';
import SrItemStatusCommandResults from '../models/Dto/srItemStatusCommandResults';
import SrItemStatusCommand from '../models/Dto/srItemStatusCommand';
import { SrItemUpdateCommand, SrItemAttributesCommand } from '../models/SrItemAttributesCommand';

export default class SrItemRepository implements ISrItemRepository {
    private readonly axios = new AxiosInstance();

    public async createSr(sr: ServiceRequestCommand): Promise<SrCommandResults> {
        let srCommandResult: SrCommandResults = { resultStatus: ResultStatus.UnKnown, resultCount: 0 };

        const response = await this.axios.teamworkApi().post('/v2/service-request', sr);
        if (response.status === 200 || response.status === 201) {
            srCommandResult = response.data;
        }
        return srCommandResult;
    }

    public async createSrItem(srCode: number, srItem: SrItemCommand): Promise<SrItemCommandResults> {
        let srCommandResult: SrItemCommandResults = { resultStatus: ResultStatus.UnKnown, resultCount: 0 };

        const response = await this.axios.teamworkApi().post(`/v2/service-request/${srCode}/srItem`, srItem);
        if (response.status === 200 || response.status === 201) {
            srCommandResult = response.data;
        }
        return srCommandResult;
    }

    public async updateSrItem(srItem: SrItemAttributesCommand): Promise<SrItemCommandResults> {
        const response = await this.axios.teamworkApi().put(`/v2/service-request/srItem/${srItem.srItem}/attributes`, srItem);
        return response.data;
    }

    public async updateSrDescription(generalDetails: SrItemUpdateCommand): Promise<SrItemUpdateCommandResult> {
        const response = await this.axios.teamworkApi().put(`/v2/service-request/srItem/${generalDetails.srItemId}`, generalDetails);
        return response.data;
    }

    public async sendInfringementNoticeEmail(infringementDetails: any) {
        this.axios.teamworkApi().post('/v2/infringement/SendNotice', infringementDetails);
    }

    public async setSrItemStatus(status: SrItemStatusCommand): Promise<SrItemStatusCommandResults> {
        let result: SrItemStatusCommandResults = { resultStatus: ResultStatus.UnKnown, resultCount: 0 };
        const response = await this.axios.teamworkApi().post(`/v2/service-request/srItem/${status.srItem}/status`, status);
        if (response.status === 200 || response.status === 201) {
            result = response.data;
        }
        return result;
    }

    public async searchSrItems(searchConditions: SearchSrItemConditions): Promise<ServiceRequestItemResults> {
        let srItemResults: ServiceRequestItemResults = { resultStatus: ResultStatus.UnKnown, resultCount: 0 };
        const searchParams = new URLSearchParams(searchConditions);
        const results = await this.axios.teamworkApi().get(`/v2/service-request/srItems?${searchParams}`);
        if (results.status === 200 || results.status === 201) {
            srItemResults = results.data;
        }
        return srItemResults;
    }
}
