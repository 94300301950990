import { Fragment } from 'react';
import { Form, FormGroup } from 'react-bootstrap';

interface FormFieldPreviewProps {
    formFieldName: string;
    valueType: string;
    isMandatory: string;
    dropdownOptions: { attributeValue: string }[];
}

export default function FormFieldPreview(props: FormFieldPreviewProps) {
    const generatePreview = (formFieldName: string, valueType: string, isMandatory: string) => {
        return (
            <FormGroup>
                <Form.Label>
                    {formFieldName}
                    {isMandatory === 'Y' ? ' *' : ''}
                </Form.Label>
                {generateInputType(valueType)}
            </FormGroup>
        );
    };

    const generateInputType = (valueType: string) => {
        switch (valueType.toLowerCase()) {
            case 'select':
                return (
                    <Form.Select>
                        <option key='none' value='none'>
                            Please select an option
                        </option>
                        {dropdownOptionValue()}
                    </Form.Select>
                );
            case 'date':
                return <Form.Control type='date' />;
            case 'time':
                return <Form.Control type='time' />;
            case 'number':
                return <Form.Control type='number' />;
            default:
                return <Form.Control type='text' />;
        }
    };

    const dropdownOptionValue = () => {
        return props.dropdownOptions.map((option, index) => {
            return (
                <option key={index} value={option.attributeValue}>
                    {option.attributeValue}
                </option>
            );
        });
    };

    return (
        <Fragment>
            <h5 className='section-header'>Form Field Preview:</h5>
            {generatePreview(props.formFieldName, props.valueType, props.isMandatory)}
        </Fragment>
    );
}
