import { Amplify, Auth, Hub } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import UnauthorizedInfo from '../pages/UnauthorizedInfo';
import AuthConfig from './AuthConfig';
import { ApplicationRoles, DataAccess, FunctionAccess } from './RoleAccess';

interface AuthInfo {
    userEmail: string,
    functionAccess: FunctionAccess,
    dataAccess: DataAccess,
}

const AuthContext = React.createContext<AuthInfo>({} as AuthInfo);
// This object is used for Non-React components that can't access context
const StaticAuthContext: AuthInfo = {} as AuthInfo 

const GetAuthInfoOrLogin = async (userData: any): Promise<AuthInfo> => {
    return Auth.currentAuthenticatedUser()
        .catch(() => Auth.federatedSignIn({ customProvider: 'WCC-AzureAD', customState: window.location.pathname }))
        .then(async (user) => {
            const session = await Auth.currentSession();
            const jwtAccessToken = session.getAccessToken().getJwtToken();
            const idToken = session.getIdToken().decodePayload();
            // Format is "[roleId, roleId]" if user has multiple user groups
            const userGroups: string[] = idToken['custom:user.groups'].match(/[\d\w-]+/g);
            
            let functionAccess = FunctionAccess.None;
            let dataAccess = DataAccess.None;

            // Get access based on the user's groups
            for (const role of ApplicationRoles) {
                if (role.groupId && userGroups.includes(role.groupId)) {
                    functionAccess |= role.functionAccess;
                    dataAccess |= role.dataAccess;
                }
            }

            return {
                userEmail: idToken.email,
                accessToken: jwtAccessToken,
                functionAccess: functionAccess,
                dataAccess: dataAccess
            } as AuthInfo;
        });
}

const AuthContextProvider = (props: any) => {
    const [authInfo, setAuthInfo] = useState<AuthInfo | null>(null)

    useEffect(() => {
        Amplify.configure(AuthConfig);
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) { 
                case 'signIn':
                case 'tokenRefresh':
                    GetAuthInfoOrLogin(data).then((value: AuthInfo) => {             
                        setAuthInfo(value);
                    });
                    break;                
                case 'signOut':
                    setAuthInfo(null);
                    break;
                case 'customOAuthState':
                    // Ensure it is a relative path, and not just the base URL
                    if (data.charAt(0) === '/' && data !== '/') {
                        window.location.href = data;
                    }
                    break;
                default:
                    break;                
            }
        });

        // Force initial login
        GetAuthInfoOrLogin(null).then((value: AuthInfo) => {             
            setAuthInfo(value);
        });
    }, []); 

    // Keep StaticAuthContext in sync
    useEffect(() => {
        if (authInfo == null) {
            StaticAuthContext.userEmail = '';
            StaticAuthContext.functionAccess = 0;
            StaticAuthContext.dataAccess = 0;
        } else {
            StaticAuthContext.userEmail = authInfo.userEmail;
            StaticAuthContext.functionAccess = authInfo.functionAccess;
            StaticAuthContext.dataAccess = authInfo.dataAccess;
        }
    }, [authInfo]);

    if (!authInfo) {
        return (<UnauthorizedInfo></UnauthorizedInfo>);
    }

    return (
        <AuthContext.Provider value={authInfo}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
export const getAccessToken = async () => (await Auth.currentSession()).getAccessToken().getJwtToken();
export { AuthContext, StaticAuthContext };
