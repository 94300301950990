import axios from 'axios';
import { AxiosInstance as LibAxiosInstance } from 'axios';
import { getAccessToken } from './auth/AuthContext';

export default class AxiosInstance {
    teamworkApi: () => LibAxiosInstance = () => {
        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_TeamworkApiUrl,
        });

        axiosInstance.interceptors.request.use(
            async (config) => {
                const jwt = await getAccessToken();
                if (config.headers && jwt) {
                    config.headers.Authorization = jwt;
                }
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        return axiosInstance;
    };
}
