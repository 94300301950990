import { useState } from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import CreateSrServices from '../services/CreateSrServices';
import { ResultStatus } from '../models/Dto/resultStatus';
import PopUpModal from '../components/PopUpModal';
import SearchCustomerModal from '../components/CreateServiceRequest/SearchCustomerModal';
import AssociatedCustomer from '../components/CreateServiceRequest/AssociatedCustomerInfo';
import ServiceRequestForm from '../components/CreateServiceRequest/ServiceRequestForm';
import ServiceRequestCommand, { SrType } from '../models/Dto/serviceRequestCommand';
import CustomerDetails from '../models/CustomerDetails';
import { useNavigate } from 'react-router-dom';
import SearchNoticeService from '../services/SearchSrService';

const createSrServices = new CreateSrServices();
const searchService = new SearchNoticeService();

export default function CreateServiceRequest() {
    const [searchCustomerModalStatus, setSearchCustomerModalStatus] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [messageHeader, setMessageHeader] = useState<string>('');
    const [messageBody, setMessageBody] = useState<string>();

    const [srDetails, setSrDetails] = useState<ServiceRequestCommand>({ srType: SrType.Unknown });
    const [customer, setCustomer] = useState<CustomerDetails>();

    const [srCreated, setSrCreationStatus] = useState(false);
    const [srNumber, setSrNumber] = useState('');
    const navigator = useNavigate();

    const createServiceRequest = async () => {
        const perorg = { perorgCode: customer?.PerorgCode };
        const newSR = { ...srDetails, ...perorg };
        try {
            if (!Object.values(SrType).includes(newSR.srType)) {
                setModalShow(true);
                setMessageHeader('Unsupported SR creation type');
                setMessageBody('This SR type is not able to be created in this app yet');
                return;
            }
            const res = await createSrServices.createServiceRequest(newSR);
            setModalShow(true);
            if (res.resultStatus === ResultStatus.Created || res.resultStatus === ResultStatus.Success) {
                setMessageHeader('Service Request created.');
                setMessageBody('The SR Number is: ' + res.results![0].serviceRequest);
                setSrCreationStatus(true);
                setSrNumber(res.results![0].serviceRequest.toString());
            } else {
                setMessageHeader("Seems there's something wrong.");
                setMessageBody('Please report to the development team with this issue.');
            }
        } catch {
            setModalShow(true);
            setMessageHeader('Server side error');
            setMessageBody('Please report to the development team with this issue.');
        }
    };

    const goToCreateSrItemPage = async () => {
        const sr = { SrCode: srNumber };
        const srDetails = await searchService.searchServiceRequests(sr);
        let srItemType: number = 10036187;
        if (srDetails[0].SrType === 'Dog Owner') {
            srItemType = 10036161;
        }
        navigator('/create-service-request-item', { state: { srType: srItemType, selectedSr: srDetails[0], customerInfo: customer } });
    };

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }} className='search-service-request'>
            <ServiceRequestForm
                srCreationStatus={srCreated}
                emitSrDetails={(srDetails: ServiceRequestCommand) => {
                    setSrDetails(srDetails);
                }}
            />
            <br />
            {customer ? <AssociatedCustomer customer={customer} /> : null}
            {srCreated ? (
                <Row className='button-group'>
                    <Button onClick={goToCreateSrItemPage}>Create an Infringement under this SR</Button>
                </Row>
            ) : (
                <Row className='button-group'>
                    <Button
                        variant='outline-primary'
                        onClick={() => {
                            setSearchCustomerModalStatus(true);
                        }}
                    >
                        {customer ? 'Associate a new Customer' : 'Associate a Customer to the Service Request'}
                    </Button>
                    {customer ? <Button onClick={createServiceRequest}>Create</Button> : null}
                </Row>
            )}
            <SearchCustomerModal
                searchCustomerModalStatus={searchCustomerModalStatus}
                onHide={(customer: CustomerDetails) => {
                    setSearchCustomerModalStatus(false);
                    setCustomer(customer);
                }}
            ></SearchCustomerModal>
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={messageHeader} messageBody={messageBody} />
        </Container>
    );
}
