import './App.scss';
import NavBar from './components/NavBar';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import MainSections from './MainSections';
import AuthContextProvider from './auth/AuthContext';
import InternalAppsLanding from './pages/InternalAppsLanding';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NotFound from './pages/NotFound';
import EnvironmentIndicator from './components/EnvironmentIndicator';

export default function App() {
    return (
        <AuthContextProvider>
            <HelmetProvider>
                {/* 
                    This is a temporary router to mock the general internal apps landing page.
                    In the future this will live outside of this repo.
                 */}
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<InternalAppsLanding />} />
                        <Route path='/hapa/*' element={<Outlet />}></Route>
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </BrowserRouter>

                <div className='page-layout'>
                    <BrowserRouter basename='/hapa'>
                        <Helmet>
                            <title>Hapa - Infringement Management System</title>
                        </Helmet>
                        <EnvironmentIndicator />
                        <NavBar />
                        <MainSections />
                        <Footer />
                    </BrowserRouter>
                </div>
            </HelmetProvider>
        </AuthContextProvider>
    );
}
