export default function EnvironmentIndicator() {
    const environmentDetector = () => {
        if (process.env.REACT_APP_TeamworkApiUrl?.includes('dev') || process.env.REACT_APP_TeamworkApiUrl?.includes('localhost')) {
            return <h5 style={{ margin: '0.5rem' }}>Development Environment</h5>;
        } else if (process.env.REACT_APP_TeamworkApiUrl?.includes('test')) {
            return <h5 style={{ margin: '0.5rem' }}>Test Environment</h5>;
        } else {
            return null;
        }
    };
    return (
        <div
            style={{
                display: 'flex',
                position: 'sticky',
                top: '0px',
                zIndex: 9999,
                width: '100%',
                justifyContent: 'center',
                backgroundColor: 'red',
                color: 'white',
                fontWeight: '900',
            }}
        >
            {environmentDetector()}
        </div>
    );
}
