export enum ResultStatus {
    UnKnown = 'UnKnown',
    Success = 'Success',
    NotFound = 'NotFound',
    Error = 'Error',
    Fail = 'Fail',
    InProgress = 'InProgress',
    Created = 'Created',
}

export interface ServiceRequestStatus {
    value: TWSRStatus;
    acceptPayment: boolean;
}

export enum TWSRStatus {
    UnKnown = 'UnKnown',
    OnGoing = 'OnGoing',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Suspended = 'Suspended',
    PaymentAllowed = 'PaymentAllowed',
}
