import { Button, Col, Container, Form, FormGroup, Row, Tooltip, Badge, Overlay } from 'react-bootstrap';
import { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SrTypeDetails } from '../models/SrType';
import FormFieldPreview from '../components/MaintenancePage/FormFieldPreview';
import SrItemAttributeService from '../services/SrItemAttributeService';
import _ from 'lodash';
import { SrAttributeType } from '../models/Dto/attributeTypeResultsDto';
import * as InfringementTypes from '../appData/infringementTypes.json';

const srItemAttributeService = new SrItemAttributeService();

export default function AddNewFormField() {
    const { infringementType } = useParams();

    const [formContent, setFormContent] = useState<SrAttributeType[]>([]);
    const [formName, setFormName] = useState<string | undefined>('');
    const [formFieldName, setFormFieldName] = useState({
        attributeDescription: 'Enter the form field name here',
    });
    const [valueType, setValueType] = useState({ attributeValidation: 'text' });
    const [isMandatory, setMandatoryValue] = useState({
        attributeMandatory: 'N',
    });
    const [sequenceNumber, setAttributeSequence] = useState({
        attributeSequence: 100,
    });
    const [dropdownOptions, setDropdownOptions] = useState<{ attributeValue: string }[]>([{ attributeValue: 'Dropdown Value 1' }]);

    const target = useRef(null);
    const [showTooltips, setShowTooltips] = useState(false);

    const getInfringementFormName = (infringementTypeID: number) => {
        const infringementTypes: any = InfringementTypes;
        const srTypes: SrTypeDetails[] = infringementTypes.SrTypeDetails;
        const srDescription = srTypes.find((srType) => {
            return srType.defaultSrItemTypeId === infringementTypeID;
        })?.srDescription;
        setFormName(srDescription);
    };

    const updateFormFieldName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormFieldName({ attributeDescription: event.currentTarget.value });
    };

    const updateValueType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValueType({ attributeValidation: event.currentTarget.value });
    };

    const updateIsMandatoryValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setMandatoryValue({ attributeMandatory: event.currentTarget.value });
    };

    const updateAttributeSequence = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAttributeSequence({ attributeSequence: +event.currentTarget.value });
    };

    useEffect(() => {
        if (infringementType) {
            getInfringementFormName(+infringementType);
            fetchFormContent(+infringementType);
        }
    }, []);

    const generateValueInputField = (dropdownOptions: { attributeValue: string }[]) => {
        return dropdownOptions.map((option, index) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                    key={index}
                >
                    <FormGroup className='wcc-form-group col-6'>
                        <Form.Label style={{ color: 'grey' }}>{option.attributeValue}</Form.Label>
                        <Form.Control
                            type='text'
                            value={option.attributeValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateDropdownOptionValue(e, index)}
                        />
                    </FormGroup>
                    {dropdownOptions.length === 1 ? null : (
                        <Button variant='outline-primary' className='remove-dropdown-value' onClick={() => removeDropdownOption(index)}>
                            Remove
                        </Button>
                    )}
                </div>
            );
        });
    };

    const addNewDropdownValue = () => {
        const lengthOfOptions = dropdownOptions.length + 1;
        setDropdownOptions([...dropdownOptions, { attributeValue: 'Dropdown Value ' + lengthOfOptions }]);
    };

    const removeDropdownOption = (index: number) => {
        let options = [...dropdownOptions];
        options.splice(index, 1);
        setDropdownOptions(options);
    };

    const updateDropdownOptionValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let options = [...dropdownOptions];
        options[index].attributeValue = event.target.value;
        setDropdownOptions(options);
    };

    const saveFormField = () => {
        let formFieldObject: any = {};

        formFieldObject.attributeValidation = valueType.attributeValidation;
        formFieldObject.attributeDescription = formFieldName.attributeDescription;
        formFieldObject.attributeValidation = valueType.attributeValidation.toUpperCase();
        formFieldObject.attributeMandatory = isMandatory.attributeMandatory;
        formFieldObject.attributeSequence = sequenceNumber.attributeSequence;
        if (valueType.attributeValidation === 'select') {
            formFieldObject.serviceRequestAttributeValues = dropdownOptions;
        }

        // To do: hook up with endpoint to test once the endpoint is developed.
    };

    const fetchFormContent = async (srType: number) => {
        let formAttributes = await srItemAttributeService.getSrItemAttributeTypes(srType);
        formAttributes = _.sortBy(formAttributes, ['attribute', 'attributeSequence']);
        setFormContent(formAttributes);
    };

    const formOrderList = (formContent: SrAttributeType[]) => {
        const newField = { ...formFieldName, ...sequenceNumber };
        let list = formContent.map((i) => {
            return {
                attributeSequence: i.attributeSequence,
                attributeDescription: i.attributeDescription,
            };
        });
        list.push(newField);
        list = _.sortBy(list, ['attributeSequence']);
        return list.map((row, index) => {
            if (row.attributeSequence === sequenceNumber.attributeSequence) {
                return (
                    <span key={index} style={{ color: 'yellow' }}>
                        {row.attributeSequence} - {row.attributeDescription}
                        <br />
                    </span>
                );
            }
            return (
                <span key={index}>
                    {row.attributeSequence} - {row.attributeDescription}
                    <br />
                </span>
            );
        });
    };

    const formFieldOrderToolTip = () => {
        return (
            <Fragment>
                <Badge bg='primary' ref={target} style={{ marginLeft: '1rem' }} onClick={() => setShowTooltips(!showTooltips)}>
                    {formContent.length !== 0 ? <span>Current Form Order</span> : null}
                </Badge>
                <Overlay target={target.current} show={showTooltips} placement='right'>
                    <Tooltip id='form-field-order-tool-tip'>{formOrderList(formContent)}</Tooltip>
                </Overlay>
            </Fragment>
        );
    };

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <div className='save-new-form-field-page-header'>
                <h4 className='section-header'>New Form Field - {formName}</h4>
                <Button onClick={saveFormField}>Save New Form Field</Button>
            </div>
            <hr />
            <Row>
                <Form className='col'>
                    <FormGroup className='wcc-form-group'>
                        <Form.Label>1. Form Field Name</Form.Label>
                        <Form.Control type='text' value={formFieldName.attributeDescription} onChange={updateFormFieldName} />
                    </FormGroup>

                    <FormGroup className='wcc-form-group'>
                        <Form.Label>2. Is Mandatory Field</Form.Label>
                        <Form.Select value={isMandatory.attributeMandatory} onChange={updateIsMandatoryValue}>
                            <option key='yes' value='Y'>
                                Yes
                            </option>
                            <option key='no' value='N'>
                                No
                            </option>
                        </Form.Select>
                    </FormGroup>

                    <FormGroup className='wcc-form-group'>
                        <Form.Label>
                            3. Form Field Sequence
                            {formFieldOrderToolTip()}
                        </Form.Label>
                        <Form.Control type='number' value={sequenceNumber.attributeSequence} onChange={updateAttributeSequence} />
                    </FormGroup>

                    <FormGroup className='wcc-form-group'>
                        <Form.Label>4. Value Type</Form.Label>
                        <Form.Select value={valueType.attributeValidation} onChange={updateValueType}>
                            <option key='text' value='char'>
                                Text
                            </option>
                            <option key='number' value='number'>
                                Number
                            </option>
                            <option key='date' value='date'>
                                Date
                            </option>
                            <option key='time' value='time'>
                                Time
                            </option>
                            <option key='select' value='select'>
                                Dropdown Selection
                            </option>
                        </Form.Select>
                    </FormGroup>

                    {valueType.attributeValidation === 'select' ? (
                        <Fragment>
                            {generateValueInputField(dropdownOptions)}
                            <Button className='add-dropdown-value' onClick={addNewDropdownValue}>
                                New Dropdown Value
                            </Button>
                        </Fragment>
                    ) : null}
                </Form>

                <Col
                    className='col-4'
                    style={{
                        margin: '0 1rem 0 4rem',
                        border: '1px solid black',
                        borderRadius: '1rem',
                    }}
                >
                    <FormFieldPreview
                        formFieldName={formFieldName.attributeDescription}
                        valueType={valueType.attributeValidation}
                        isMandatory={isMandatory.attributeMandatory}
                        dropdownOptions={dropdownOptions}
                    />
                </Col>
            </Row>
        </Container>
    );
}
