import axios from 'axios';
import { DogBreedSuggestionDto } from '../models/Dto/DogBreedSuggestionDto';
import IDogOwnerSrRepository from './Impl/IDogOwnerSrRepository';
import AxiosInstance from '../axiosInstance';
import { DogOwnerInfo } from '../models/Dto/dogOwnerInfoDto';
import { DogSearchParameters, DogRegistrationSearchResults, DogAndOwnerSearchDto } from '../models/Dto/dogRegistrationSearchResults';
import { ServiceRequestStatus } from '../models/Dto/serviceRequestStatus';
import { ResultStatus } from '../models/Dto/resultStatus';
import DogAndOwnerSearchResultsDto from '../models/Dto/DogAndOwnerSearchResultsDto';
import OwnerAndDogSearchResultsDto, { OwnerSearchParameters } from '../models/Dto/OwnerAndDogSearchResultsDto';

export default class DogOwnerSrRepository implements IDogOwnerSrRepository {
    private readonly axios = new AxiosInstance();

    public async getDogOwnerInfo(dogOwnerSr: number): Promise<DogOwnerInfo> {
        let dogOwnnerInfo: DogOwnerInfo = {};

        const response = await this.axios.teamworkApi().get('/v2/service-request/Dog/ReRegOwner?OwnerSr=' + dogOwnerSr);

        if (response.data.resultCount > 0) {
            dogOwnnerInfo = response.data.results[0];
            return dogOwnnerInfo;
        }

        return dogOwnnerInfo;
    }

    public async searchDogAndOwnerDetails(searchParameters: DogSearchParameters): Promise<DogAndOwnerSearchResultsDto> {
        const searchParams = new URLSearchParams(searchParameters);
        const response = await this.axios.teamworkApi().get('/v2/service-request/Dog/DogAndOwner?' + searchParams);
        return response.data;
    }

    public async searchOwnerAndDogDetails(searchParameters: OwnerSearchParameters): Promise<OwnerAndDogSearchResultsDto> {
        const searchParams = new URLSearchParams(searchParameters);
        const response = await this.axios.teamworkApi().get('/v2/service-request/Dog/OwnerAndDog?' + searchParams);
        return response.data;
    }

    public async searchDogInfo(searchParameters: DogSearchParameters): Promise<DogRegistrationSearchResults> {
        let searchResults: DogRegistrationSearchResults = { resultStatus: ResultStatus.UnKnown, resultCount: 0, results: [] };
        const searchParams = new URLSearchParams(searchParameters);
        const response = await this.axios.teamworkApi().get('/v2/service-request/Dog?' + searchParams);
        if (response.data.resultStatus === 'Success') {
            searchResults = response.data;
        }
        return searchResults;
    }

    public async getDogInfoByDogSrCode(dogSrCode: number): Promise<DogAndOwnerSearchDto> {
        let dogInfo: DogAndOwnerSearchDto = {
            dogSr: 0,
            balance: 0,
            desexed: false,
            dogDob: '',
            status: { value: ServiceRequestStatus.Unknown, acceptPayment: false },
        };

        const response = await this.axios.teamworkApi().get('/v2/service-request/Dog?SrCode=' + dogSrCode);

        if (response.data.resultCount > 0) {
            dogInfo = response.data.results[0];
            return dogInfo;
        }

        return dogInfo;
    }

    public async getDogBreedSuggestion(keyword: string): Promise<DogBreedSuggestionDto> {
        const url = 'https://uat.data.govt.nz/api/3/action/datastore_search';
        const resourceID = 'resource_id=a34776ef-5fee-4977-8ab5-495f2a295e5c';
        const limit = 'limit=10';
        const query = `q=${keyword}`;

        const response = await axios.get(`${url}?${resourceID}&${limit}&${query}`);

        return response.data;
    }
}
