import { Container } from 'react-bootstrap';

function Footer() {
    return (
        <footer>
            <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontSize: '0.75rem' }}>Copyright © 2022 Wellington City Council. All rights reserved.</span>
                <a
                    style={{ fontWeight: '600', textDecoration: 'none', color: '#FFFFFF' }}
                    href='mailto: SCDigitalServicesDevelopment@wcc.govt.nz?subject=Infringement app feedback'
                >
                    Give Feedback
                </a>
            </Container>
        </footer>
    );
}

export default Footer;
