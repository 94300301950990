import SrItemFormMaintenanceTable from '../components/MaintenancePage/SrItemFormMaintenanceTable';
import { SrAttributeType } from '../models/Dto/attributeTypeResultsDto';
import _ from 'lodash';
import { useState } from 'react';
import SrItemAttributeService from '../services/SrItemAttributeService';
import { Container, Form, FormGroup, Button, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SrItemType } from '../models/SrType';

const srItemAttributeService = new SrItemAttributeService();

export default function Maintenance() {
    const [srItem, setSelectSrItem] = useState('');
    const [formContent, setFormContent] = useState<SrAttributeType[]>([]);
    const [isLoading, setLoadingStatus] = useState(true);

    const navigate = useNavigate();

    const updateSelectedForm = async (element: React.ChangeEvent<HTMLSelectElement>) => {
        setLoadingStatus(true);
        setSelectSrItem(element.currentTarget.value);
        let formAttributes = await srItemAttributeService.getSrItemAttributeTypes(+element.currentTarget.value);
        formAttributes = _.sortBy(formAttributes, ['attribute', 'attributeSequence']);
        setFormContent(formAttributes);
        setLoadingStatus(false);
    };

    const addNewFormField = () => {
        navigate('/maintenance/' + srItem.toString() + '/new-form-field');
    };

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <div className='page-header'>
                <h4 className='header-title'>Maintenance</h4>
            </div>
            <hr />
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Select an Infringement Form</Form.Label>
                    <Form.Select aria-label='Select an Infringement Form' value={srItem} onChange={updateSelectedForm}>
                        <option value='' key=''>
                            None
                        </option>
                        <option value={SrItemType.DogInfringement} key='Dog Infringement'>
                            Dog Owner Infringement
                        </option>
                        <option value={SrItemType.GeneralInfringement} key='General Infringement'>
                            General Infringement
                        </option>
                    </Form.Select>
                </FormGroup>
                {srItem !== '' && !isLoading ? (
                    <Button className='add-new-form-field' onClick={addNewFormField}>
                        Add a new form field
                    </Button>
                ) : null}
            </Row>
            {srItem !== '' ? <SrItemFormMaintenanceTable formContent={formContent} isLoading={isLoading} /> : null}
        </Container>
    );
}
