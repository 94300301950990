import { Col, Container, Modal, Row } from 'react-bootstrap';
import SrItemDetailsSection from '../SearchPage/SrItemDetailsSection';
import GenericInfringementForm from '../CreateInfringementPage/GenericInfringementForm';
import SrItemDetails from '../../models/SrItemDetails';
import SrResult from '../../models/SrResult';

interface popupModal {
    onHide(): void;
    modalStatus: boolean;
    srItemDetails?: SrItemDetails;
}

export default function PreviewInfringementModal(props: popupModal) {
    const selectedSr: SrResult = { SrNumber: props.srItemDetails?.SrNumber! };

    return (
        <Modal show={props.modalStatus} fullscreen={true} onHide={props.onHide} aria-labelledby='infringementDetailModal' centered>
            <Modal.Body>
                <Container>
                    <Modal.Header closeButton>
                        <Modal.Title>Infringement Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={4}>{props.srItemDetails ? <SrItemDetailsSection previewMode={true} selectedSrItem={props.srItemDetails} /> : null}</Col>
                            <Col sm={8}>
                                <GenericInfringementForm
                                    previewMode={true}
                                    selectedSr={selectedSr}
                                    srType={props.srItemDetails?.srItemTypeId!}
                                    selectedSrItem={props.srItemDetails}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Container>
            </Modal.Body>
        </Modal>
    );
}
