import { differenceInYears } from 'date-fns';
import DogColourCodeUtils from '../utils/dogColourCodeUtils';
import { DogDetails } from './DogAndOwnerModel';
import dogBreedList from '../appData/dogBreedList.json';

export interface IdValuePair {
    id: string;
    value: string;
}

export default class DogInfoModel {
    dogSrNumber?: IdValuePair;
    registrationNo?: IdValuePair;
    dogName?: IdValuePair;
    dogAge?: IdValuePair;
    dogBreed1?: IdValuePair;
    dogBreed2?: IdValuePair;
    dogColour1?: IdValuePair;
    dogColour2?: IdValuePair;
    dogGender?: IdValuePair;

    constructor(dogAndOwnerDetails: DogDetails) {
        this.dogSrNumber = { id: '5044639', value: dogAndOwnerDetails.dogSr.toString() };
        this.registrationNo = { id: '5044640', value: dogAndOwnerDetails.registrationNo?.toString() || '' };
        this.dogName = { id: '5044655', value: dogAndOwnerDetails.dogName || '' };
        this.dogAge = { id: '5044642', value: this.getDogAge(dogAndOwnerDetails.dogDob) };
        this.dogBreed1 = { id: '5044641', value: this.getDogBreed(dogAndOwnerDetails.dogBreed1) };
        this.dogBreed2 = { id: '5045424', value: this.getDogBreed(dogAndOwnerDetails.dogBreed2) }; // Prod env only, doesn't work in dev and test env as attribute is newly added and the type id is different
        this.dogColour1 = { id: '5044643', value: this.getDogColour(dogAndOwnerDetails.dogColour1) };
        this.dogColour2 = { id: '5044644', value: this.getDogColour(dogAndOwnerDetails.dogColour2) };
        this.dogGender = { id: '5044593', value: dogAndOwnerDetails.dogGender?.toLowerCase() || 'none' };
    }

    private dogColourCodeUtils = new DogColourCodeUtils();

    private getDogAge = (dogDob: string | undefined) => {
        if (dogDob) {
            return differenceInYears(new Date(), new Date(dogDob)).toString();
        } else {
            return '';
        }
    };

    private getDogBreed = (dogBreed: string | undefined) => {
        if (dogBreed) {
            if (dogBreedList.includes(dogBreed)) {
                switch (dogBreed) {
                    case 'AKIT':
                        return 'JAKI';
                    case 'MBSH':
                    case 'GBSH':
                    case 'TBSH':
                        return 'BSHE';
                    case 'CHIS':
                        return 'CHI';
                    case 'CEST':
                    case 'BUTM':
                    case 'NOTE':
                    case 'BTER':
                    case 'DADT':
                    case 'NTER':
                        return 'TERR';
                    case 'CORC':
                    case 'CORP':
                        return 'CORG';
                    case 'HPUL':
                        return 'PULI';
                    case 'SHSH':
                        return 'SHET';
                    case 'PLSH':
                        return 'SHEE';
                    case 'PJAC':
                        return 'JACK';
                    case 'PUGG':
                        return 'PUG';
                    case 'COLS':
                        return 'SMCO';
                    case 'ESET':
                        return 'ESET';
                    case 'COLB':
                        return 'BDIE';
                    case 'CHIL':
                        return 'LHCH';
                    case 'HVIZ':
                        return 'VIZS';
                    case 'STCA':
                        return 'CATT';
                    case 'CRXX':
                        return 'CROS';
                    default:
                        return 'OTHR';
                }
            } else {
                return dogBreed;
            }
        } else {
            return 'none';
        }
    };

    private getDogColour = (dogColour: string | undefined) => {
        if (dogColour) {
            return this.dogColourCodeUtils.getCorrectColourCode(dogColour);
        } else {
            return 'none';
        }
    };
}
