import { MojExportCommand } from '../models/MojExportCommand';
import IInfringementServices from './Impl/IInfringementServices';
import InfringementRepository from '../repositories/InfringementRepository';

export default class InfringementService implements IInfringementServices {
    private readonly infringementRepository = new InfringementRepository();

    public async exportForMoj(mojExportCommand: MojExportCommand): Promise<string> {
        const srItems = mojExportCommand.ServiceRequestItems.map((srItem) => {
            return 'ServiceRequestItems=' + srItem;
        });
        const requestedByEmail = 'requestedByEmail=' + mojExportCommand.requestedByEmail;
        const infringementType = 'infringementType=' + mojExportCommand.infringementType;
        const serviceRequestItems = srItems.join('&');
        const params = requestedByEmail + '&' + infringementType + '&' + serviceRequestItems;
        const res = await this.infringementRepository.exportForMoj(params);
        return res;
    }
}
