import { Fragment, useEffect, useState, ChangeEvent, useContext, useRef } from 'react';
import { Button, Form, FormGroup, FormLabel, Row, ListGroup } from 'react-bootstrap';
import SrItemService from '../../services/SrItemService';
import SrItemAttributeService from '../../services/SrItemAttributeService';
import { SrAttributeType, SrAttributeValue } from '../../models/Dto/attributeTypeResultsDto';
import FormFieldGeneratorUtils from '../../utils/formFieldGenertorUtils';
import { format, subMonths } from 'date-fns';
import PopUpModal from '../PopUpModal';
import _ from 'lodash';
import { AttributeTypeValuePair } from '../../models/Dto/serviceRequestCommand';
import { CircularProgress } from '@mui/material';
import SrResult from '../../models/SrResult';
import SrItemDetails from '../../models/SrItemDetails';
import DogOwnerInfringementAdditionalFields from './DogOwnerInfringementAdditionalFields';
import DogInfringementAdditionalInfo from '../../models/DogInfringementAdditionalInfo';
import DogSearchModal from './DogSearchModal';
import DogInfoModel from '../../models/DogInfoModel';
import { DogAndOwnerDetailsModel, OwnerDetails, DogDetails, OwnerAndDogsDetailsModel } from '../../models/DogAndOwnerModel';
import { AuthContext } from '../../auth/AuthContext';
import eventBus from '../../utils/eventBus';
import { SrItemUpdateCommand } from '../../models/SrItemAttributesCommand';
import { SrItemType } from '../../models/SrType';

const srItemAttributeService = new SrItemAttributeService();
const srItemService = new SrItemService();
const formFieldGenertorUtils = new FormFieldGeneratorUtils();

interface infringementFormProps {
    selectedSr: SrResult;
    srType: number;
    dogAndOwnerDetails?: DogAndOwnerDetailsModel;
    ownerAndDogsDetails?: OwnerAndDogsDetailsModel;
    selectedSrItem?: SrItemDetails;
    previewMode?: boolean;
    creationMode?: boolean;
    emitUpdatedSrItem?(srItem: AttributeTypeValuePair[]): void;
    emitGeneralDetails?(generalDetails: SrItemUpdateCommand): void;
}

export default function GenericInfringementForm(props: infringementFormProps) {
    const [isLoading, setLoadingStatus] = useState(true);
    const [infringementSrItemAttributes, setInfringementSrItem] = useState<SrAttributeType[]>([]);

    const [infringementForm, setInfringementForm] = useState<AttributeTypeValuePair[]>([]);

    const [srItemTypeId, setSrItemTypeId] = useState(10036187);
    const [infringementDescription, setInfringementDescription] = useState('');
    const [infringementComment, setInfringementComment] = useState('');

    const [additionalInfo, setAdditionalInfo] = useState<DogInfringementAdditionalInfo>({});

    const [modalShow, setModalShow] = useState(false);
    const [messageHeader, setMessageHeader] = useState<string>('');
    const [messageBody, setMessageBody] = useState<string>('');
    const [modalMessageContent, setModalMessageContent] = useState(<></>);
    const [isProcessing, setIsProcessingStatus] = useState(false);

    const [dogSearchModalStatus, setDogSearchModalStatus] = useState(false);

    const minDate = format(subMonths(new Date(), 12), 'yyyy-MM-dd');
    const maxDate = format(new Date(), 'yyyy-MM-dd');

    const [dogOwnerInfo, setDogOwnerInfo] = useState<OwnerDetails>();
    const [dogBasicInfo, setDogBasicInfo] = useState<DogDetails[]>([]);
    const [isCreated, setIsCreated] = useState(false);

    const authInfo = useContext(AuthContext);
    const selectRef = useRef<HTMLSelectElement>(null);

    const [dogFullInfo, setDogFullInfo] = useState<DogInfoModel>();

    const fetchSrItem = async () => {
        setLoadingStatus(true);

        const srItemForm = await srItemAttributeService.getSrItemAttributeTypes(props.srType);
        setInfringementSrItem(srItemForm);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 50);

        if (props.selectedSr.SrType === 'Dog Owner') {
            setSrItemTypeId(+SrItemType.DogInfringement);
            if (props.ownerAndDogsDetails?.dogs) {
                setDogBasicInfo(props.ownerAndDogsDetails.dogs);
                setDogOwnerInfo(props.ownerAndDogsDetails.owner);
            } else if (props.dogAndOwnerDetails?.dog) {
                setDogBasicInfo([props.dogAndOwnerDetails.dog]);
                setDogOwnerInfo(props.dogAndOwnerDetails.owner);
            }
        }
        setLoadingStatus(false);
        if (props.selectedSrItem) {
            loadSrItemDetails();
            setInfringementDescription(props.selectedSrItem.Description!);
            setInfringementComment(props.selectedSrItem.comment!);
        }
    };

    const setFormFieldStatus = (formFieldName: string) => {
        const disabledFormFields = [
            'Infringement Notice Number',
            'Infringement Form number',
            'Reissued Reminder',
            'Reminder Served By',
            'Reminder Served On',
            'Fee Paid in Full',
            'Court Record Number',
            'CRN of Previous reminder',
            'Part Payment',
            'Total Fee',
        ];
        if (props.creationMode && disabledFormFields.includes(formFieldName)) {
            return true;
        } else if (props.previewMode) {
            return true;
        } else if (isCreated) {
            return true;
        }
        return false;
    };

    const loadSrItemDetails = () => {
        props.selectedSrItem?.SrItemInfo?.forEach((item) => {
            const dateFields = [5044631, 5044579, 5044647, 5044675, 5044760, 5044764, 5044773, 5044776, 5045422, 5045443];
            if (item.attributeTypeId && dateFields.includes(+item.attributeTypeId)) {
                let date = '';
                if (item.attributeValue) {
                    date = format(new Date(item.attributeValue), 'yyyy-MM-dd');
                } else if (item.attributeValueDate) {
                    date = format(new Date(item.attributeValueDate), 'yyyy-MM-dd');
                }
                if (date) {
                    updateInfringementFormUI(item.attributeTypeId.toString(), date);
                    updateInfringementFormValue(item.attributeTypeId.toString(), date);
                }
            } else if (item.attributeTypeId && item.attributeValue) {
                updateInfringementFormUI(item.attributeTypeId.toString(), item.attributeValue);
                updateInfringementFormValue(item.attributeTypeId.toString(), item.attributeValue);
            }
        });
    };

    const createDogNameDropdownOptions = (dogsInfo: DogDetails[]) => {
        if (dogsInfo.length === 0) {
            return null;
        } else {
            return (
                <FormGroup className='wcc-form-group col-8'>
                    <FormLabel>Please select the dog's name</FormLabel>
                    <Form.Select id='selectedDogInfo' onChange={getDogFullInfo} ref={selectRef} disabled={isCreated}>
                        <option key='null' value='null'>
                            Please select an option
                        </option>
                        {generateDogDropdownOptions(dogsInfo)}
                    </Form.Select>
                    <br />
                </FormGroup>
            );
        }
    };

    const generateDogDropdownOptions = (dogsInfo: DogDetails[]) => {
        const uniqByDogName = _.uniqBy(dogsInfo, 'dogName');
        return uniqByDogName.map((dog) => {
            return (
                <option key={dog.dogSr} value={dog.dogSr}>
                    {dog.dogName}
                </option>
            );
        });
    };

    const getDogFullInfo = async (el: ChangeEvent<HTMLSelectElement>) => {
        if (el.currentTarget.value !== 'null') {
            const selectedDog = _.find(dogBasicInfo, (dog) => {
                return dog.dogSr === +el.currentTarget.value;
            });
            if (selectedDog) {
                const dogDetails = new DogInfoModel(selectedDog);
                setDogFullInfo(dogDetails);
                setupSelectedDogValues(dogDetails);
            }
        }
    };

    const setupSelectedDogValues = (dogFullInfo: DogInfoModel) => {
        _.forEach(dogFullInfo, (info) => {
            if (info && info.value) {
                updateInfringementFormUI(info.id, info.value);
                if (info.value !== '' && info.value !== 'none') {
                    updateInfringementFormValue(info.id, info.value);
                }
            }
        });
    };

    const updateInfringementFormValue = (attributeType: string, attributeValue: string) => {
        const index = infringementForm?.findIndex((attribute) => attribute.srItemAttributeType === attributeType);
        if (index !== -1) {
            infringementForm[index].attributeValue = attributeValue;
            if (props.emitUpdatedSrItem) {
                props.emitUpdatedSrItem(infringementForm);
            }
        } else {
            let form = infringementForm;
            form.push({ srItemAttributeType: attributeType, attributeValue: attributeValue });
            setInfringementForm(form);
            if (props.emitUpdatedSrItem) {
                props.emitUpdatedSrItem(form!);
            }
        }
    };

    //seperating different part of the form
    const generateForm = (srAttributeTypes: SrAttributeType[]) => {
        const infringementNumbers = srAttributeTypes.filter((attribute) => {
            return attribute.attributeSequence <= 200;
        });
        const offenceDetails = srAttributeTypes.filter((attribute) => {
            return attribute.attributeSequence > 200 && attribute.attributeSequence <= 920;
        });
        const dogInfo = srAttributeTypes.filter((attribute) => {
            return attribute.attributeSequence > 920 && attribute.attributeSequence <= 1600;
        });
        const issuingInfo = srAttributeTypes.filter((attribute) => {
            return attribute.attributeSequence > 1600 && attribute.attributeSequence <= 1900;
        });
        const reminderInfo = srAttributeTypes.filter((attribute) => {
            return attribute.attributeSequence > 1900 && attribute.attributeSequence <= 2200;
        });
        const otherInfo = srAttributeTypes.filter((attribute) => {
            return attribute.attributeSequence > 2200;
        });

        const createFormFields = (types: SrAttributeType[]) => {
            return types.map((attributeType: SrAttributeType) => {
                return generateInputField(attributeType);
            });
        };

        return (
            <Fragment>
                {createFormFields(infringementNumbers)}
                <h5 style={{ fontWeight: '700', margin: '1rem 0' }}>Offence Details</h5>
                {createFormFields(offenceDetails)}
                {dogInfo.length > 0 ? (
                    <Fragment>
                        <h5 style={{ fontWeight: '700', margin: '1rem 0' }}>Dog Info</h5>
                        <Row style={{ alignItems: 'center' }}>
                            {createDogNameDropdownOptions(dogBasicInfo)}
                            {dogSearchButton()}
                        </Row>
                    </Fragment>
                ) : null}
                {createFormFields(dogInfo)}
                <hr />
                {createFormFields(issuingInfo)}
                <hr />
                {createFormFields(reminderInfo)}
                <hr />
                {createFormFields(otherInfo)}
            </Fragment>
        );
    };

    const dogSearchButton = () => {
        if (isCreated || props.previewMode) {
            return;
        } else {
            return (
                <Button
                    className='dog-search-button'
                    variant='outline-primary'
                    onClick={() => {
                        setDogSearchModalStatus(true);
                    }}
                >
                    Search Dog Info
                </Button>
            );
        }
    };

    const generateInputField = (srAttributeType: SrAttributeType) => {
        const hiddenFormFields = [
            'Offence Precedent Code',
            'Prosecuting Agency',
            'Informant Name',
            'Informant Address',
            'Court of Hearing',
            'Transaction Type',
        ];
        if (!hiddenFormFields.includes(srAttributeType.attributeDescription!)) {
            return (
                <FormGroup className='wcc-form-group col-6' key={srAttributeType.srItemAttributeType}>
                    <Form.Label className={['Mandatory?', 'Mandatory'].includes(srAttributeType.statusDescription!) ? 'form-label-required' : ''}>
                        {srAttributeType.attributeDescription === 'Offence Location' ? 'Street/Road' : srAttributeType.attributeDescription}
                    </Form.Label>
                    {generateInputType(srAttributeType)}
                </FormGroup>
            );
        }
    };

    const generateInputType = (srAttributeType: SrAttributeType) => {
        if (srAttributeType.serviceRequestAttributeValues) {
            return (
                <Form.Select
                    aria-label={srAttributeType.attributeDescription}
                    id={srAttributeType.srItemAttributeType.toString()}
                    onChange={updateSelectedAttributeOption}
                    disabled={setFormFieldStatus(srAttributeType.attributeDescription!)}
                >
                    <option key='none' value='none'>
                        Please select an option
                    </option>
                    {generateDropdownList(srAttributeType.serviceRequestAttributeValues)}
                </Form.Select>
            );
        } else if (srAttributeType.attributeDescription === 'Offence Time') {
            return (
                <Form.Control
                    id={srAttributeType.srItemAttributeType.toString()}
                    type='time'
                    name='time'
                    onChange={updateAttributeValue}
                    disabled={setFormFieldStatus(srAttributeType.attributeDescription!)}
                />
            );
        } else if (srAttributeType.attributeValidation === 'DATE') {
            return (
                <Form.Control
                    id={srAttributeType.srItemAttributeType.toString()}
                    type={formFieldGenertorUtils.inputType(srAttributeType.attributeValidation)}
                    onChange={updateAttributeValue}
                    min={minDate}
                    max={maxDate}
                    disabled={setFormFieldStatus(srAttributeType.attributeDescription!)}
                />
            );
        } else {
            return (
                <Form.Control
                    id={srAttributeType.srItemAttributeType.toString()}
                    type={formFieldGenertorUtils.inputType(srAttributeType.attributeValidation)}
                    autoComplete='off'
                    onWheelCapture={(e) => {
                        e.currentTarget.blur();
                    }}
                    onChange={updateAttributeValue}
                    defaultValue={formFieldGenertorUtils.getDefaultInputFieldValue(srAttributeType.attributeDescription, dogFullInfo)}
                    disabled={setFormFieldStatus(srAttributeType.attributeDescription!)}
                />
            );
        }
    };

    const updateInfringementFormUI = (id: string, value: string) => {
        let element = document.getElementById(id);
        if (element !== null) {
            let selectElement = element as HTMLSelectElement;
            selectElement.value = value;
        }
    };

    const generateDropdownList = (attributeValues: SrAttributeValue[]) => {
        return attributeValues.map((attributeValue, i) => {
            return (
                <option key={attributeValue.srItemAttributeValue} value={attributeValue.attributeValue}>
                    {attributeValue.attributeValueDescr
                        ? attributeValue.attributeValue + ' (' + attributeValue.attributeValueDescr + ')'
                        : attributeValue.attributeValue}
                </option>
            );
        });
    };

    const updateSelectedAttributeOption = (element: ChangeEvent<HTMLSelectElement>) => {
        updateInfringementFormValue(element.currentTarget.id, element.currentTarget.value);

        // if it is the infringement section field, auto update the value on the 'Total Fee' and 'Offence Precedent Code' field base on the selection
        if (element.currentTarget.ariaLabel === 'Infringement Section') {
            const offenceSection = _.find(infringementSrItemAttributes, (srItemAttribute) => {
                return srItemAttribute.srItemAttributeType === 5044578;
            });
            const selectedAttributeValue = _.find(offenceSection?.serviceRequestAttributeValues, (attributeValue) => {
                return attributeValue.attributeValue === element.currentTarget.value;
            });
            if (selectedAttributeValue) {
                const totalFeeField = _.find(infringementSrItemAttributes, (srItemAttribute) => {
                    return srItemAttribute.attributeDescription === 'Total Fee';
                });
                const totalFeeFieldId = totalFeeField?.srItemAttributeType!;
                const fee = parseFloat((Math.round(selectedAttributeValue.feeAmount! * 100) / 100).toString()).toFixed(2);
                updateInfringementFormValue(totalFeeFieldId.toString(), fee);
                updateInfringementFormUI(totalFeeFieldId.toString(), fee);

                const offencePrecedentCode = _.find(infringementSrItemAttributes, (srItemAttribute) => {
                    return srItemAttribute.attributeDescription === 'Offence Precedent Code';
                });
                const offencePrecedentCodeId = offencePrecedentCode?.srItemAttributeType!;
                updateInfringementFormValue(offencePrecedentCodeId.toString(), selectedAttributeValue.statsCode!.toString());
                updateInfringementFormUI(offencePrecedentCodeId.toString(), selectedAttributeValue.statsCode!.toString());
            }
        }
    };

    const updateAttributeValue = (element: ChangeEvent<HTMLInputElement>) => {
        const AttributeTypeValuePair = { srItemAttributeType: element.currentTarget.id, attributeValue: element.currentTarget.value };

        const index = infringementForm?.findIndex((attribute) => attribute.srItemAttributeType === element.currentTarget.id);
        if (index !== -1) {
            infringementForm[index].attributeValue = element.currentTarget.value;
        } else {
            let form = infringementForm;
            form.push(AttributeTypeValuePair);
            setInfringementForm(form);
        }
    };

    const updateSrItemGeneralDetails = (el: ChangeEvent<HTMLInputElement>) => {
        let generalDetails: SrItemUpdateCommand = { srItemId: 0 };
        if (props.selectedSrItem?.srItem) {
            generalDetails = {
                srItemId: props.selectedSrItem.srItem,
                description: infringementDescription,
                comment: infringementComment,
            };
        }
        switch (el.currentTarget.id) {
            case 'infringement-description':
                setInfringementDescription(el.currentTarget.value);
                generalDetails.description = el.currentTarget.value;
                break;
            case 'infringement-comment':
                setInfringementComment(el.currentTarget.value);
                generalDetails.comment = el.currentTarget.value;
                break;
            default:
                break;
        }
        if (props.emitGeneralDetails && generalDetails.srItemId !== 0) {
            props.emitGeneralDetails(generalDetails);
        }
    };

    const srItemAdditionalInfo = () => {
        return (
            <Fragment>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Infringement Description</Form.Label>
                    <Form.Control
                        id='infringement-description'
                        as='textarea'
                        value={infringementDescription}
                        onChange={updateSrItemGeneralDetails}
                        rows={4}
                        disabled={setFormFieldStatus('')}
                    />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                        id='infringement-comment'
                        as='textarea'
                        value={infringementComment}
                        onChange={updateSrItemGeneralDetails}
                        rows={4}
                        disabled={setFormFieldStatus('')}
                    />
                </FormGroup>
            </Fragment>
        );
    };

    const mandatoryFieldCheck = () => {
        const mandatoryFields = _.filter(infringementSrItemAttributes, (attribute) => {
            return attribute.statusDescription === 'Mandatory?';
        });

        const filledFields = infringementForm.map((field) => {
            return field.srItemAttributeType;
        });

        const emptyField = _.find(mandatoryFields, (mandatoryField) => {
            return !filledFields.includes(mandatoryField.srItemAttributeType.toString());
        });

        if (emptyField) {
            setModalShow(true);
            setIsProcessingStatus(false);
            setMessageHeader('Please fill all the mandatory fields.');
            setMessageBody('You are missing ' + emptyField.attributeDescription);
            return false;
        } else {
            return true;
        }
    };

    const setupMojAttributeValues = () => {
        const mojAttributeFields = ['Court of Hearing', 'Prosecuting Agency', 'Informant Name', 'Informant Address', 'Part Payment', 'Reissued Reminder'];
        _.forEach(mojAttributeFields, (mojAttributeField) => {
            _.find(infringementSrItemAttributes, (attribute) => {
                if (attribute.attributeDescription === mojAttributeField) {
                    const defaultValue = _.find(attribute.serviceRequestAttributeValues, (attributeValue) => {
                        return attributeValue.defaultValue === 'Y';
                    });
                    if (defaultValue?.attributeValue) {
                        updateInfringementFormValue(attribute.srItemAttributeType.toString(), defaultValue.attributeValue);
                    } else if (attribute.attributeDescription === 'Reissued Reminder') {
                        updateInfringementFormValue(attribute.srItemAttributeType.toString(), 'N');
                    } else {
                        updateInfringementFormValue(attribute.srItemAttributeType.toString(), attribute?.serviceRequestAttributeValues![0].attributeValue!);
                    }
                }
            });
        });
    };

    const getDogBreedDescriptionFromDogBreedCode = (dogBreedField: string) => {
        const dogBreed = _.find(infringementSrItemAttributes, (attribute) => {
            return attribute.attributeDescription === dogBreedField;
        });

        if (dogBreed && dogBreed?.srItemAttributeType) {
            const element = document.getElementById(dogBreed.srItemAttributeType.toString()) as HTMLSelectElement;

            if (dogBreed && dogBreed.serviceRequestAttributeValues && element) {
                const selectedDogBreed = _.find(dogBreed.serviceRequestAttributeValues, (attributeValue) => {
                    return attributeValue.attributeValue === element.value;
                });
                if (selectedDogBreed && selectedDogBreed.attributeValueDescr) {
                    return selectedDogBreed.attributeValueDescr;
                }
            }
        }
    };

    const createInfringement = async () => {
        setModalShow(true);
        setIsProcessingStatus(true);
        setModalMessageContent(<></>);
        setupMojAttributeValues();
        if (mandatoryFieldCheck()) {
            const infringementItem = {
                srItemTypeId: srItemTypeId,
                description: infringementDescription,
                comment: infringementComment,
                srAttributes: infringementForm,
            };
            let additionalInfomation = additionalInfo;
            additionalInfomation.createdBy = authInfo.userEmail;
            additionalInfomation.dogBreedFullDescription = getDogBreedDescriptionFromDogBreedCode('Dog Breed');
            additionalInfomation.dogBreed2FullDescription = getDogBreedDescriptionFromDogBreedCode('Dog Breed 2');
            try {
                await srItemService.createSrItem(props.selectedSr.SrNumber, infringementItem, dogOwnerInfo, additionalInfomation);
                setIsProcessingStatus(false);
                setMessageHeader('Infringement created.');
                setMessageBody('The SR number is ' + props.selectedSr.SrNumber);
                setIsCreated(true);
                eventBus.dispatch('isCreated', true);
            } catch (error: any) {
                setIsProcessingStatus(false);
                setMessageHeader('Infringement failed to create.');
                setMessageBody(error.response.data.title);
                setModalMessageContent(
                    <ListGroup style={{ maxHeight: '30rem', overflowY: 'auto' }}>
                        {error.response.data.errors.srAttributes.map((error: string) => {
                            return <ListGroup.Item key={error}>{error}</ListGroup.Item>;
                        })}
                    </ListGroup>
                );
            }
        }
    };

    useEffect(() => {
        fetchSrItem();
        eventBus.on('customerDetailsUpdated', (customerDetails: OwnerDetails) => {
            setDogOwnerInfo(customerDetails);
        });
        return () => {
            eventBus.remove('customerDetailsUpdated');
        };
    }, []);

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />
            ) : (
                <Fragment>
                    <h5 style={{ fontWeight: '700', marginBottom: '1rem' }}>Infringement Details</h5>
                    <Row>{generateForm(infringementSrItemAttributes)}</Row>
                    <hr />
                    <Row>{srItemAdditionalInfo()}</Row>
                    {props.selectedSr.SrType === 'Dog Owner' ? (
                        <Row>
                            <DogOwnerInfringementAdditionalFields
                                formStatus={isCreated}
                                emitAdditionalInfo={(info: DogInfringementAdditionalInfo) => setAdditionalInfo(info)}
                            />
                        </Row>
                    ) : null}
                    {props.selectedSrItem || isCreated ? null : (
                        <Row className='button-group'>
                            <Button onClick={createInfringement}>Create</Button>
                        </Row>
                    )}
                </Fragment>
            )}
            <DogSearchModal
                dogSearchModalStatus={dogSearchModalStatus}
                onHide={(selectedDog?: DogAndOwnerDetailsModel) => {
                    if (selectedDog) {
                        const dogInfo = new DogInfoModel(selectedDog.dog);
                        setupSelectedDogValues(dogInfo);
                        if (document.getElementById('selectedDogInfo')) {
                            selectRef.current!.value = 'null';
                        }
                    }
                    setDogSearchModalStatus(false);
                }}
            />
            <PopUpModal
                modalStatus={modalShow}
                onHide={() => setModalShow(false)}
                isUpdating={isProcessing}
                messageHeader={messageHeader}
                messageBody={messageBody}
                messageBodyContent={modalMessageContent}
            />
        </Fragment>
    );
}
