export default class SrStatusExtensionUtils {
    public getSrStatusExtension(offenceDetail: string): number {
        switch (offenceDetail) {
            case '18 - Wilful obstruction of dog control officer or ranger':
                return 1133;
            case '19(2) - failure to supply information':
                return 1134;
            case '19A(2) - false dog info':
                return 1135;
            case '20(5) - fouling':
                return 1136;
            case '20(5) - off lead':
                return 1137;
            case '20(5) - off lead in public place':
                return 1138;
            case '20(5) - off lead in public right of way':
                return 1139;
            case '20(5) - in prohibited area':
                return 1140;
            case '23A(2) - failure to take education or obedience programme':
                return 1141;
            case 'Probationary owner non-compliance':
                return 1142;
            case '28(5) - disqual. non-compliance':
                return 1143;
            case '32(2) - dangerous dog non-compliance':
                return 1144;
            case '32(4) - dangerous dog sale or transfer':
                return 1145;
            case '33EC(1) - menacing dog non-compliance':
                return 1146;
            case '33F(3)  - advice failure -menacing muzzle-leash':
                return 1147;
            case '36A(6) - microchip non-compliance':
                return 1148;
            case '41 - false statement relating to dog registration':
                return 1149;
            case '41A - falsely notifying death of dog':
                return 1150;
            case '42 - dog regn failure':
                return 1151;
            case '46(4) - dog regn ID fraud':
                return 1152;
            case '48(3) - dog ownership non-compliance':
                return 1153;
            case '49(4) - change address not advised':
                return 1154;
            case '51(1) - tag tampering':
                return 1155;
            case '52A - dog unconfined':
                return 1156;
            case '53(1) - dog not controlled':
                return 1257;
            case '54(2) - dog not cared for':
                return 1258;
            case '54A - leash not carried':
                return 1259;
            case '55(7) - noncompliance - barking abatement notice':
                return 1260;
            case '62(4) - dangerous dog unmuzzled':
                return 1261;
            case '62(4) - dangerous dog unleashed':
                return 1262;
            case '62(5) - advice failure -dangerous muzzle-leash':
                return 1263;
            case '72(2) - dog released from custody':
                return 1264;
            case '72(2) - dog uplifted from custody':
                return 1265;
            default:
                return 0;
        }
    }
}
