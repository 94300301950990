import { Row, Col, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import GenericInfringementForm from '../components/CreateInfringementPage/GenericInfringementForm';
import SrResult from '../models/SrResult';
import CustomerDetails from '../components/CreateInfringementPage/CustomerDetails';
import { DogOwnerInfo } from '../models/Dto/dogOwnerInfoDto';
import { DogAndOwnerDetailsModel, OwnerAndDogsDetailsModel } from '../models/DogAndOwnerModel';
import ServiceRequestInfo from '../components/CreateInfringementPage/ServiceRequestInfo';
import CustomerInfoModel from '../models/CustomerDetails';
interface locationState {
    srType: number;
    selectedSr: SrResult;
    reRegDogOwner?: DogOwnerInfo;
    dogAndOwnerDetails?: DogAndOwnerDetailsModel;
    ownerAndDogsDetails?: OwnerAndDogsDetailsModel;
    customerInfo?: CustomerInfoModel;
}

export default function CreateServiceRequestItem() {
    const location = useLocation();
    const locationState = location.state as locationState;

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <h4 className='section-header'>Create Infringement</h4>
            <hr />
            <Row style={{ marginTop: '2rem' }}>
                <Col sm={4}>
                    <CustomerDetails
                        reRegDogOwner={locationState.reRegDogOwner}
                        dogAndOwnerModel={locationState.dogAndOwnerDetails}
                        ownerAndDogsDetails={locationState.ownerAndDogsDetails}
                        selectedSr={locationState.selectedSr}
                        customerInfo={locationState.customerInfo}
                    />
                    <hr style={{ color: '#dee2e6' }} />
                    <ServiceRequestInfo selectedSr={locationState.selectedSr} />
                </Col>
                <Col sm={8}>
                    <GenericInfringementForm
                        selectedSr={locationState.selectedSr}
                        srType={locationState.srType}
                        dogAndOwnerDetails={locationState.dogAndOwnerDetails}
                        ownerAndDogsDetails={locationState.ownerAndDogsDetails}
                        creationMode={true}
                    />
                </Col>
            </Row>
        </Container>
    );
}
