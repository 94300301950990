import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import WccLogo from '../assets/images/wcc-logo.png';
import { FunctionAccess, RenderIfHasAccess } from '../auth/RoleAccess';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import { useContext } from 'react';

function NavBar() {
    let location = useLocation();
    const authInfo = useContext(AuthContext);

    const maintenanceSection = () => {
        if (process.env.REACT_APP_TeamworkApiUrl?.includes('dev') || process.env.REACT_APP_TeamworkApiUrl?.includes('localhost')) {
            return (
                <Nav.Link style={location.pathname === '/maintenance' ? highlightNavBarItem() : undefined} as={Link} className='nav-bar-link' to='/maintenance'>
                    Maintenance
                </Nav.Link>
            );
        }
    };

    const highlightNavBarItem = () => {
        return { textDecoration: 'underline', textUnderlineOffset: '6px', textDecorationThickness: '3px' };
    };

    return (
        <Navbar className='wcc-nav-bar' expand='lg'>
            <Navbar.Brand className='nav-bar-logo'>
                <img alt='Wellington City Council' src={WccLogo} width='144' height='36' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
                <Nav style={{ marginLeft: 'auto', alignItems: 'center' }}>
                    <RenderIfHasAccess requiresFunction={FunctionAccess.Create}>
                        <Nav.Link
                            style={location.pathname.startsWith('/create') ? highlightNavBarItem() : undefined}
                            as={Link}
                            className='nav-bar-link'
                            to='/create-infringement'
                        >
                            Create
                        </Nav.Link>
                    </RenderIfHasAccess>
                    <RenderIfHasAccess requiresFunction={FunctionAccess.Read}>
                        <Nav.Link
                            style={location.pathname === '/notice-search' ? highlightNavBarItem() : undefined}
                            as={Link}
                            className='nav-bar-link'
                            to='/notice-search'
                        >
                            Search
                        </Nav.Link>
                    </RenderIfHasAccess>
                    <RenderIfHasAccess requiresFunction={FunctionAccess.Export}>
                        <Nav.Link
                            style={location.pathname === '/export-for-moj' ? highlightNavBarItem() : undefined}
                            as={Link}
                            className='nav-bar-link'
                            to='/export-for-moj'
                        >
                            Export for MOJ
                        </Nav.Link>
                    </RenderIfHasAccess>
                    <RenderIfHasAccess requiresFunction={FunctionAccess.Maintain}>{maintenanceSection()}</RenderIfHasAccess>
                    <Tooltip title={authInfo.userEmail}>
                        <Avatar sx={{ bgcolor: '#006CD4', width: 36, height: 36 }}>{authInfo.userEmail[0]}</Avatar>
                    </Tooltip>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;
