import IInfringementRepository from './Impl/IInfingementRepository';
import AxiosInstance from '../axiosInstance';

export default class InfringementRepository implements IInfringementRepository {
    private readonly axios = new AxiosInstance();

    public async exportForMoj(mojExportCommand: string): Promise<string> {
        const response = await this.axios.teamworkApi().get('/v2/Infringement/GenerateMoJExport?' + mojExportCommand);
        return response.data;
    }
}
