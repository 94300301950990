import { useState, MouseEvent, Fragment, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Button, Container } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { DogAndOwnerDetailsModel } from '../../models/DogAndOwnerModel';

const headCells = [
    {
        id: 'owner',
        label: 'Dog Owner',
    },
    {
        id: 'address',
        label: 'Address',
    },
    {
        id: 'dogDescription',
        label: 'Dog Info',
    },
];

interface DogSearchResultsTableProps {
    searchingState: boolean;
    dogInfo: DogAndOwnerDetailsModel[] | null;
    emitDogInfo(selectedDogInfo: DogAndOwnerDetailsModel | null): void;
    maxTableHeight?: number;
}

export default function DogSearchResultsTable(props: DogSearchResultsTableProps) {
    const [selectedDogInfo, setSelected] = useState<DogAndOwnerDetailsModel | null>(null);
    const rowsPerPage = 10;
    const [page, setPage] = useState(0);
    const [maxTableHeight, setMaxTableHeight] = useState(1024);

    const handleClick = (event: MouseEvent<unknown>, row: DogAndOwnerDetailsModel) => {
        setSelected(row);
    };

    const isSelected = (dogSr: number) => {
        return selectedDogInfo?.dog.dogSr === dogSr;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const emitDogDetails = async (row: DogAndOwnerDetailsModel) => {
        props.emitDogInfo(row);
    };

    useEffect(() => {
        setPage(0);
        if (props.maxTableHeight) {
            setMaxTableHeight(props.maxTableHeight);
        }
    }, [props.searchingState]);

    const resultTable = () => {
        if (props.searchingState) {
            return <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />;
        } else if (!props.searchingState && props.dogInfo !== null && props.dogInfo.length === 0) {
            return (
                <Container className='empty-search-results'>
                    <h3>No result found.</h3>
                    <h5>Seems we don't have this dog's info in WCC's database</h5>
                </Container>
            );
        } else if (props.dogInfo !== null) {
            return (
                <Table sx={{ minWidth: 750 }} aria-labelledby='dog search results'>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id}>
                                    <TableSortLabel>{headCell.label}</TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dogInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const isItemSelected = isSelected(row.dog.dogSr!);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.dog.dogSr}
                                    selected={isItemSelected}
                                >
                                    <TableCell component='th' id={labelId} scope='row'>
                                        <Button style={{ textAlign: 'left' }} variant='link' onClick={() => emitDogDetails(row)}>
                                            {row.owner.nameFormatted}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button style={{ textAlign: 'left' }} variant='link' onClick={() => emitDogDetails(row)}>
                                            {row.owner.fullAddress}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button style={{ textAlign: 'left' }} variant='link' onClick={() => emitDogDetails(row)}>
                                            <b>{row.dog.dogName}</b>, TAG NO: <u>{row.dog.registrationNo}</u>, ADDRESS: <u>{row.dog.dogLocation}</u>
                                            <br />
                                            COLOUR: {row.dog.dogColour1} {row.dog.dogColour2}, BREED: {row.dog.dogBreed1} {row.dog.dogBreed2}, GENDER:{' '}
                                            {row.dog.dogGender}, DOG SR NO: {row.dog.dogSr}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            );
        }
    };

    return (
        <Fragment>
            <Paper style={{ marginTop: '3rem' }}>
                <TableContainer sx={{ maxHeight: maxTableHeight }}>{resultTable()}</TableContainer>
                {!props.searchingState && props.dogInfo !== null && props.dogInfo.length > 0 ? (
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component='div'
                        count={props.dogInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                ) : null}
            </Paper>
        </Fragment>
    );
}
