import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

export const enum FunctionAccess {
    None = 0,
    Read = 1 << 0, // Could be split to search & read if required
    Create = 1 << 1,
    Edit = 1 << 2,
    Withdraw = 1 << 3,
    Export = 1 << 4,
    Maintain = 1 << 5,
    All = ~None,
}

export const enum DataAccess {
    None = 0,
    Dog = 1 << 0,
    Building = 1 << 1,
    BWof = 1 << 2,
    ResMgt = 1 << 3,
    Litter = 1 << 4,
    Food = 1 << 5,
    All = ~None,
    Generic = Building | BWof | ResMgt | Litter | Food,
}

interface ApplicationRole {
    name: string;
    groupId?: string;
    functionAccess: FunctionAccess;
    dataAccess: DataAccess;
}

// These roles map to Azure AD groups via the groupId property
export const ApplicationRoles: ApplicationRole[] = [
    {
        name: 'Admin',
        groupId: process.env.REACT_APP_GroupId_Admin,
        functionAccess: FunctionAccess.All,
        dataAccess: DataAccess.All,
    },
    {
        name: 'Contributor - Dog',
        groupId: process.env.REACT_APP_GroupId_Contributor_Dog,
        functionAccess: FunctionAccess.Read | FunctionAccess.Create | FunctionAccess.Edit | FunctionAccess.Withdraw | FunctionAccess.Export,
        dataAccess: DataAccess.Dog,
    },
    {
        name: 'Contributor - Building',
        groupId: process.env.REACT_APP_GroupId_Contributor_Building,
        functionAccess: FunctionAccess.Read | FunctionAccess.Edit | FunctionAccess.Export,
        dataAccess: DataAccess.Building | DataAccess.ResMgt,
    },
    {
        name: 'Hutt City Animal Services',
        groupId: process.env.REACT_APP_GroupId_HCAS,
        functionAccess: FunctionAccess.Read | FunctionAccess.Create | FunctionAccess.Edit | FunctionAccess.Withdraw,
        dataAccess: DataAccess.Dog,
    },
];

export const RenderIfHasAccess = (props: React.PropsWithChildren<{ requiresFunction?: FunctionAccess; requiresData?: DataAccess }>) => {
    if (props.children && HasAccess(props.requiresFunction, props.requiresData)) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return null;
    }
};

export const HasAccess = (requiresFunction?: FunctionAccess, requiresData?: DataAccess): boolean => {
    const authInfo = useContext(AuthContext);

    if (requiresFunction && !(authInfo.functionAccess & requiresFunction)) {
        return false;
    }
    if (requiresData && !(authInfo.dataAccess & requiresData)) {
        return false;
    }

    return true;
};
