import { SrSearchResultsDto } from '../models/Dto/srResultDto';
import ISearchNoticeRepository from './Impl/ISearchNoticeRepository';
import AxiosInstance from '../axiosInstance';
import { SearchSrConditions } from '../models/searchConditionsModel';
import { ResultStatus } from '../models/Dto/resultStatus';

export default class SearchNoticeRepository implements ISearchNoticeRepository {
    private readonly axios = new AxiosInstance();

    public async GetSrResults(searchConditions: SearchSrConditions): Promise<SrSearchResultsDto> {
        let srResultDto: SrSearchResultsDto = { resultStatus: ResultStatus.UnKnown, resultCount: 0, results: [] };

        const searchParams = new URLSearchParams(searchConditions);

        const response = await this.axios.teamworkApi().get(`/v2/service-request?${searchParams}`);

        if (response.data.resultCount > 0) {
            srResultDto = response.data;
        }

        return srResultDto;
    }
}
