import { ChangeEvent, useState } from 'react';
import { Modal, Container, Button, FormGroup, Form, Row } from 'react-bootstrap';
import CustomerDetails from '../../models/CustomerDetails';
import { CustomerSearchConditions } from '../../models/searchConditionsModel';
import CustomerServices from '../../services/CustomerServices';
import CustomerSearchResultsTable from './CustomerSearchResultsTable';
import PopUpModal from '../PopUpModal';

interface SearchCustomerModalProps {
    searchCustomerModalStatus: boolean;
    onHide(customer?: CustomerDetails): void;
}

export default function SearchCustomerModal(props: SearchCustomerModalProps) {
    const customerServices = new CustomerServices();

    const [modalShow, setModalShow] = useState(false);
    const [messageHeader, setMessageHeader] = useState<string>('');

    const [perorgNumber, setPerorgNumber] = useState<string>('');
    const [customerName, setCustomerName] = useState<string>('');
    const [customerType, setCustomerType] = useState<string>('Person');
    const [searchingState, setSearchingState] = useState<boolean>(false);
    const [customerSearchResults, setCustomerSearchResults] = useState<CustomerDetails[] | null>(null);

    const customerTypes = ['Association', 'Business Unit', 'Club', 'Company', 'Employee', 'Person', 'Trust', 'Trustee', 'Unknown'];

    const generateStatusDropdownList = () => {
        return customerTypes.map((customerType: string) => {
            return (
                <option key={customerType} value={customerType}>
                    {customerType}
                </option>
            );
        });
    };

    const updatePerorgNumber = (element: ChangeEvent<HTMLInputElement>) => {
        setPerorgNumber(element.currentTarget.value);
    };

    const updateCustomerName = (element: ChangeEvent<HTMLInputElement>) => {
        setCustomerName(element.currentTarget.value);
    };

    const updateCustomerType = (element: ChangeEvent<HTMLSelectElement>) => {
        setCustomerType(element.currentTarget.value);
    };

    const clearSearch = () => {
        setPerorgNumber('');
        setCustomerName('');
        setCustomerType('');
        setCustomerSearchResults(null);
    };

    const searchCustomer = async () => {
        let searchValues: CustomerSearchConditions = {};
        if (perorgNumber) {
            const perorg = { PerorgCode: perorgNumber };
            searchValues = { ...searchValues, ...perorg };
        }
        if (customerName) {
            const customer = { CustomerName: customerName };
            searchValues = { ...searchValues, ...customer };
        }
        if (customerType) {
            const type = { CustomerType: customerType };
            searchValues = { ...searchValues, ...type };
        }
        if (Object.keys(searchValues).length !== 0) {
            setSearchingState(true);
            const results = await customerServices.searchCustomer(searchValues);
            setCustomerSearchResults(results);
            setSearchingState(false);
        } else {
            setModalShow(true);
            setMessageHeader('Please enter a search value.');
        }
    };

    return (
        <Modal show={props.searchCustomerModalStatus} fullscreen={true} onHide={props.onHide} aria-labelledby='search customer modal' centered>
            <Container>
                <Modal.Header closeButton>
                    <Modal.Title>Search Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <FormGroup className='wcc-form-group col-4'>
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control type='text' value={customerName} onChange={updateCustomerName} />
                        </FormGroup>
                        <FormGroup className='wcc-form-group col-4'>
                            <Form.Label>Customer Type</Form.Label>
                            <Form.Select value={customerType} onChange={updateCustomerType}>
                                <option></option>
                                {generateStatusDropdownList()}
                            </Form.Select>
                        </FormGroup>
                        <FormGroup className='wcc-form-group col-4'>
                            <Form.Label>Perorg Number</Form.Label>
                            <Form.Control type='number' value={perorgNumber} onChange={updatePerorgNumber} />
                        </FormGroup>
                    </Row>
                    <Row className='button-group'>
                        <Button variant='outline-primary' onClick={clearSearch}>
                            Clear
                        </Button>
                        <Button onClick={searchCustomer}>Search</Button>
                    </Row>
                    <CustomerSearchResultsTable
                        searchingState={searchingState}
                        customers={customerSearchResults}
                        emitCustomer={(customer: CustomerDetails) => {
                            props.onHide(customer);
                        }}
                    />
                </Modal.Body>
            </Container>
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={messageHeader} />
        </Modal>
    );
}
