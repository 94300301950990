export default class CustomerDetails {
    CustomerName: string | null;
    PerorgCode: number;
    Initials: string | null;
    Status: string | null;
    CustomerType: string | null;
    NZNonStandard: string | null;
    NZUrban: string | null;
    Phone: string | null;
    Fax: string | null;
    Cellular: string | null;
    DDI: string | null;
    AfterHours: string | null;
    Alternative: string | null;
    AlternativePhone: string | null;
    Delivery: string | null;
    DirectDial: string | null;
    EMail: string | null;
    Email: string | null;
    Mailing: string | null;
    NZPOBox: string | null;
    NZPrivateBag: string | null;
    NZRural: string | null;
    Overseas: string | null;
    Pager: string | null;
    Physical: string | null;
    PhysicalLocation: string | null;
    Postal: string | null;
    DateOfBirth: string;

    constructor() {
        this.CustomerName = '';
        this.PerorgCode = 0;
        this.Initials = '';
        this.Status = '';
        this.CustomerType = '';
        this.NZNonStandard = '';
        this.NZUrban = '';
        this.Phone = '';
        this.Fax = '';
        this.Cellular = '';
        this.DDI = '';
        this.AfterHours = '';
        this.Alternative = '';
        this.AlternativePhone = '';
        this.Delivery = '';
        this.DirectDial = '';
        this.EMail = '';
        this.Email = '';
        this.Mailing = '';
        this.NZPOBox = '';
        this.NZPrivateBag = '';
        this.NZRural = '';
        this.Overseas = '';
        this.Pager = '';
        this.Physical = '';
        this.PhysicalLocation = '';
        this.Postal = '';
        this.DateOfBirth = '';
    }
}
