export interface SrTypeDetails {
    srTypeCode: number;
    srTypeName: string;
    srTypeDisplayName?: string;
    srDescription: string;
    defaultSrItemTypeId: number;
    srCategory?: number;
    extensions?: SrExtension[];
}

export interface SrExtension {
    srTypeExtension?: number;
    extensionDescription?: string;
}

export enum SrItemType {
    DogInfringement = '10036161',
    GeneralInfringement = '10036187',
}

export enum SrTypeExtension {
    Building = '1012',
    BWoF = '1013',
    ResMgt = '1014',
    Litter = '1015',
    FoodAct14 = '1057',
    Pool = '1065',
    RMACompliance = '1066',
    Alcohol = '1067',
}
