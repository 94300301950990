import SrItemAttributeRepository from '../repositories/SrItemAttributeRepository';
import ISrItemAttributeService from './Impl/ISrItemAttributeService';
import { SrAttributeType } from '../models/Dto/attributeTypeResultsDto';
import _ from 'lodash';

export default class SrItemAttributeService implements ISrItemAttributeService {
    private readonly srItemAttributeRepository = new SrItemAttributeRepository();

    public async getSrItemAttributeTypes(srType: number): Promise<SrAttributeType[]> {
        let results: SrAttributeType[] = [];
        const response = await this.srItemAttributeRepository.getSrItemAttributeTypes(srType);
        if (response.resultCount > 0) {
            return this.additionalMandatoryFields(response.results);
        }
        return results;
    }

    private additionalMandatoryFields(fields: SrAttributeType[]) {
        const additionalMandatoryFields = [5044639, 5044595, 5044631, 5044645];
        return _.forEach(fields, (field) => {
            if (additionalMandatoryFields.includes(field.srItemAttributeType)) {
                field.statusDescription = 'Mandatory?';
            }
        });
    }

    public async getSrItemAttributeValues(srItemAttributeType: number): Promise<SrAttributeType[]> {
        let results: SrAttributeType[] = [];
        const response = await this.srItemAttributeRepository.getSrItemAttributeValues(srItemAttributeType);
        if (response.resultCount > 0) {
            return response.results;
        }
        return results;
    }
}
