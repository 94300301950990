import DogBreedSuggestion from '../models/DogBreedSuggestion';
import DogOwnerServiceRequestRepository from '../repositories/DogOwnerSrRepository';
import IDogOwnerSrService from './Impl/IDogOwnerSrService';
import { DogAndOwnerSearchDto, DogSearchParameters } from '../models/Dto/dogRegistrationSearchResults';
import { DogOwnerInfo } from '../models/Dto/dogOwnerInfoDto';
import { DogAndOwnerDto } from '../models/Dto/DogAndOwnerSearchResultsDto';
import _ from 'lodash';
import { DogAndOwnerDetailsModel, OwnerAndDogsDetailsModel } from '../models/DogAndOwnerModel';
import { OwnerSearchParameters } from '../models/Dto/OwnerAndDogSearchResultsDto';

export default class DogOwnerSrService implements IDogOwnerSrService {
    private readonly dogOwnerServiceRequestRepository = new DogOwnerServiceRequestRepository();

    public async searchDogInfo(searchParams: DogSearchParameters): Promise<DogAndOwnerSearchDto[]> {
        let results: DogAndOwnerSearchDto[] = [];
        const dogs = await this.dogOwnerServiceRequestRepository.searchDogInfo(searchParams);
        results = dogs.results;
        return results;
    }

    public async searchDogAndOwnerDetails(searchParams: DogSearchParameters): Promise<DogAndOwnerDetailsModel[]> {
        const response = await this.dogOwnerServiceRequestRepository.searchDogAndOwnerDetails(searchParams);
        return this.seperateDogOwners(response.results);
    }

    private seperateDogOwners(results: DogAndOwnerDto[]): DogAndOwnerDetailsModel[] {
        let dogAndOwnerList: DogAndOwnerDetailsModel[] = [];
        const list = _.map(results, (item) => {
            return _.map(item.owners, (owner) => {
                const detailModel: DogAndOwnerDetailsModel = {
                    dog: {
                        dogSr: item.dogSr,
                        balance: item.balance,
                        dogName: item.dogName,
                        dogLocation: item.dogLocation,
                        microchipNo: item.microchipNo,
                        registrationNo: item.registrationNo,
                        desexed: item.desexed,
                        dogType: item.dogType,
                        dogDescription: item.dogDescription,
                        dogBreed1: item.dogBreed1,
                        dogBreed2: item.dogBreed2,
                        dogColour1: item.dogColour1,
                        dogColour2: item.dogColour2,
                        dogGender: item.dogGender,
                        dogDob: item.dogDob,
                        status: item.status,
                    },
                    owner: owner,
                };
                return detailModel;
            });
        });
        dogAndOwnerList = list.flat();
        return dogAndOwnerList;
    }

    public async searchOwnerAndDogDetails(searchParams: OwnerSearchParameters): Promise<OwnerAndDogsDetailsModel[]> {
        const response = await this.dogOwnerServiceRequestRepository.searchOwnerAndDogDetails(searchParams);

        const ownerAndDogsDetails = _.map(response.results, (item) => {
            const detailModel: OwnerAndDogsDetailsModel = {
                owner: {
                    ownerSr: item.ownerSr,
                    customerNo: item.customerNo,
                    givenName: item.givenName,
                    lastName: item.lastName,
                    dob: item.dob,
                    nameFormatted: item.nameFormatted,
                    fullAddress: item.fullAddress,
                    printAddress1: item.printAddress1,
                    printAddress2: item.printAddress2,
                    printAddress3: item.printAddress3,
                    printAddress4: item.printAddress4,
                    printAddress5: item.printAddress5,
                    printAddress6: item.printAddress6,
                },
                dogs: item.dogs,
            };
            return detailModel;
        });
        return ownerAndDogsDetails;
    }

    public async getDogOwnerInfo(dogOwnerSr: number): Promise<DogOwnerInfo> {
        const dogOwnerInfo = await this.dogOwnerServiceRequestRepository.getDogOwnerInfo(dogOwnerSr);
        return dogOwnerInfo;
    }

    public async getDogBreedSuggestion(keyword: string): Promise<DogBreedSuggestion[]> {
        const dogBreedSuggestion: DogBreedSuggestion[] = [];

        const response = await this.dogOwnerServiceRequestRepository.getDogBreedSuggestion(keyword);

        if (response.result.records.length > 0) {
            response.result.records.forEach((record) => {
                let dogBreed: DogBreedSuggestion = {};
                dogBreed.ID = record._id;
                dogBreed.Code = record.Code;
                dogBreed.Breed = record.Description;
                dogBreed.Rank = record.rank;
                dogBreedSuggestion.push(dogBreed);
            });
        }

        return dogBreedSuggestion;
    }
}
