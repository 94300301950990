import { Navigate, Route, Routes } from 'react-router-dom';
import CreateInfringement from './pages/SearchServiceRequest';
import SearchInfringements from './pages/SearchInfringements';
import ExportForMoj from './pages/ExportForMOJ';
import CreateServiceRequest from './pages/CreateServiceRequest';
import Maintenance from './pages/Maintenance';
import AddNewFormField from './pages/AddNewFormField';
import InfringementDetails from './pages/InfringementDetails';
import CreateServiceRequestItem from './pages/CreateServiceRequestItem';
import { FunctionAccess, HasAccess } from './auth/RoleAccess';
import React from 'react';
import NotFound from './pages/NotFound';

export default function MainSections() {
    return (
        <Routes>
            {(HasAccess(FunctionAccess.Create) && <Route path='' element={<Navigate to='/create-infringement' />} />) ||
                (HasAccess(FunctionAccess.Read) && <Route path='' element={<Navigate to='/notice-search' />} />) ||
                (HasAccess(FunctionAccess.Export) && <Route path='' element={<Navigate to='/export-for-moj' />} />) ||
                (HasAccess(FunctionAccess.Maintain) && <Route path='' element={<Navigate to='/maintenance' />} />)}

            {HasAccess(FunctionAccess.Read) && (
                <React.Fragment>
                    <Route path='/notice-search' element={<SearchInfringements />} />
                    <Route path='/infringement/:infringementType/:srItemNumber' element={<InfringementDetails />} />
                </React.Fragment>
            )}

            {HasAccess(FunctionAccess.Create) && (
                <React.Fragment>
                    <Route path='/create-infringement' element={<CreateInfringement />} />
                    <Route path='/create-service-request' element={<CreateServiceRequest />} />
                    <Route path='/create-service-request-item' element={<CreateServiceRequestItem />} />
                </React.Fragment>
            )}

            {HasAccess(FunctionAccess.Export) && (
                <React.Fragment>
                    <Route path='/export-for-moj' element={<ExportForMoj />} />
                </React.Fragment>
            )}

            {HasAccess(FunctionAccess.Maintain) && (
                <React.Fragment>
                    <Route path='/maintenance' element={<Maintenance />} />
                    <Route path='/maintenance/:infringementType/new-form-field' element={<AddNewFormField />} />
                </React.Fragment>
            )}

            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}
