export type MojExportCommand = {
    requestedByEmail: string;
    infringementType: InfringementType;
    ServiceRequestItems: string[];
};

export enum InfringementType {
    Dog = 'Dog',
    Building = 'Building',
    BWof = 'BWof',
    ResMgt = 'ResMgt',
    Litter = 'Litter',
    FoodAct14 = 'FoodAct14',
    Unknown = 'Unknown',
}
