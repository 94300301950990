import { ResultStatus } from './resultStatus';

export default interface CustomerSearchResultsDto {
    resultStatus: ResultStatus;
    message: string | null;
    resultCount: number;
    results: Customer[];
}

export interface Customer {
    perorgCode: number;
    status: string | null;
    customerName: string | null;
    initials: string | null;
    customerType: string | null;
    dateOfBirth: string;
    contactAddresses: CustomerContactAddress[] | null;
}

export interface CustomerContactAddress {
    contactAddressType: ContactAddressType | null;
    displayAddress: string | null;
}

export enum ContactAddressType {
    NZNonStandard = 'NZ Non Standard',
    NZUrban = 'NZ Urban',
    Phone = 'Phone',
    Fax = 'Fax',
    Cellular = 'Cellular',
    DDI = 'DDI',
    AfterHours = 'After Hours',
    Alternative = 'Alternative',
    AlternativePhone = 'Alternative Phone',
    Delivery = 'Delivery',
    DirectDial = 'Direct Dial',
    EMail = 'E-Mail',
    Email = 'Email',
    Mailing = 'Mailing',
    NZPOBox = 'NZ PO Box',
    NZPrivateBag = 'NZ Private Bag',
    NZRural = 'NZ Rural',
    Overseas = 'Overseas',
    Pager = 'Pager',
    Physical = 'Physical',
    PhysicalLocation = 'Physical Location',
    Postal = 'Postal',
}
