import AxiosInstance from '../axiosInstance';
import ISrItemAttributeRepository from './Impl/ISrItemAttributeRepository';
import AttributeTypeResultsDto from '../models/Dto/attributeTypeResultsDto';

export default class SrItemAttributeRepository implements ISrItemAttributeRepository {
    private readonly axios = new AxiosInstance();

    public async getSrItemAttributeTypes(srType: number): Promise<AttributeTypeResultsDto> {
        const response = await this.axios.teamworkApi().get(`/v2/service-request/srItemType/${srType}/attributes`);
        return response.data;
    }

    public async getSrItemAttributeValues(srItemAttributeType: number): Promise<AttributeTypeResultsDto> {
        const response = await this.axios.teamworkApi().get(`/v2/service-request/attributes/${srItemAttributeType}`);
        return response.data;
    }
}
