import ISrItemService from './Impl/ISrItemServices';
import SrItemRepository from '../repositories/SrItemRepository';
import { SrItemUpdateCommandResult, SrItemCommandResults } from '../models/SrCommandResults';
import SrItemDetails from '../models/SrItemDetails';
import { SearchSrItemConditions } from '../models/searchConditionsModel';
import SrItemCommand from '../models/Dto/srItemCommand';
import { OwnerDetails } from '../models/DogAndOwnerModel';
import DogInfringementAdditionalInfo from '../models/DogInfringementAdditionalInfo';
import { ResultStatus } from '../models/Dto/resultStatus';
import _ from 'lodash';
import SrItemStatusCommand, { DogOwnerSrStatusType } from '../models/Dto/srItemStatusCommand';
import SrItemStatusCommandResults from '../models/Dto/srItemStatusCommandResults';
import SrStatusExtensionUtils from '../utils/srStatusExtensionUtils';
import { SrItemUpdateCommand, SrItemAttributesCommand } from '../models/SrItemAttributesCommand';
import { SrItemType } from '../models/SrType';

export default class SrItemService implements ISrItemService {
    private readonly srItemRepository = new SrItemRepository();
    private readonly srStatusExtensionUtils = new SrStatusExtensionUtils();

    public async createSrItem(
        srCode: number,
        srItem: SrItemCommand,
        dogOwnerInfo?: OwnerDetails,
        additionalInfo?: DogInfringementAdditionalInfo
    ): Promise<SrItemCommandResults> {
        const response = await this.srItemRepository.createSrItem(srCode, srItem);
        if (srItem.srItemTypeId === +SrItemType.DogInfringement && response.resultStatus === ResultStatus.Created) {
            this.sendInfringementNoticeEmail(srCode, srItem, response, dogOwnerInfo, additionalInfo);
            await this.setSrItemStatusToNoticeIssued(srItem, response);
            await this.updateDogInfringementFormNumber(response.results![0].srItem);
        }
        return response;
    }

    public async updateSrItem(srItem: SrItemAttributesCommand): Promise<SrItemCommandResults> {
        const response = await this.srItemRepository.updateSrItem(srItem);
        return response;
    }

    public async updateSrItemGeneralDetails(generalDetails: SrItemUpdateCommand): Promise<SrItemUpdateCommandResult> {
        const response = await this.srItemRepository.updateSrDescription(generalDetails);
        return response;
    }

    private async setSrItemStatusToNoticeIssued(srItem: SrItemCommand, response: SrItemCommandResults) {
        const infringementSection = _.find(srItem.srAttributes, (attribute) => {
            return attribute.srItemAttributeType === '5044578';
        });
        if (infringementSection?.attributeValue) {
            const statusExtension = this.srStatusExtensionUtils.getSrStatusExtension(infringementSection.attributeValue);
            const srStatus: SrItemStatusCommand = {
                srItem: response.results![0].srItem,
                srStatusType: DogOwnerSrStatusType.NoticeIssued,
                srStatusExtension: statusExtension,
            };
            this.updateSrItemStatus(srStatus);
        }
    }

    private async updateDogInfringementFormNumber(srItemNumber: number) {
        const infringementFormNumber: SrItemAttributesCommand = {
            srItem: srItemNumber,
            srAttributes: [{ srItemAttributeType: '5044654', attributeValue: srItemNumber.toString() }],
        };
        await this.updateSrItem(infringementFormNumber);
    }

    public async batchUpdateSrItemStatus(srItems: SrItemStatusCommand[]): Promise<number[]> {
        let failedItems: number[] = [];
        for (const srItem of srItems) {
            try {
                await this.updateSrItemStatus(srItem);
            } catch (error: any) {
                failedItems.push(srItem.srItem);
            }
        }
        return failedItems;
    }

    public async updateSrItemStatus(status: SrItemStatusCommand): Promise<SrItemStatusCommandResults> {
        const response = await this.srItemRepository.setSrItemStatus(status);
        return response;
    }

    public async searchSrItems(searchConditions: SearchSrItemConditions): Promise<SrItemDetails[]> {
        const response = await this.srItemRepository.searchSrItems(searchConditions);
        const results = response.results;

        if (results && results.length > 0) {
            return results.map((item) => {
                return {
                    SrNumber: item.serviceRequestCode,
                    Description: item.description,
                    SrItemInfo: item.srAttributes,
                    srItem: item.srItem,
                    srItemCode: item.srItemCode,
                    srItemTypeId: item.srItemTypeId,
                    srItemType: item.srItemType,
                    purpose: item.purpose,
                    comment: item.comment,
                    srStatusDescription: item.srStatusDescription,
                    staffMemberCode: item.staffMemberCode,
                    srItemTypeExtension: item.srItemTypeExtension,
                    srTypeExtension: item.srTypeExtension,
                };
            });
        } else {
            return [];
        }
    }

    private sendInfringementNoticeEmail(
        srCode: number,
        srItem: SrItemCommand,
        srCommandResult: SrItemCommandResults,
        dogOwnerInfo?: OwnerDetails,
        additionalInfo?: DogInfringementAdditionalInfo
    ) {
        let infringementFormValues = srItem;

        let infringementDetails = {
            noticeNumber: srCommandResult.results![0].srItem,
            nameTitle: dogOwnerInfo?.title,
            firstName: dogOwnerInfo?.givenName,
            lastName: dogOwnerInfo?.lastName,
            fullAddress: dogOwnerInfo?.fullAddress,
            dateOfBirth: dogOwnerInfo?.dob,
            dogOwnerSr: srCode,
        };
        infringementDetails = { ...infringementDetails, ...infringementFormValues, ...additionalInfo };
        this.srItemRepository.sendInfringementNoticeEmail(infringementDetails);
    }
}
