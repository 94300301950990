import CustomerSearchResultsDto from '../models/Dto/CustomerSearchResultsDto';
import ICustomerSearchRepository from './Impl/ICustomerSearchRepository';
import { CustomerSearchConditions } from '../models/searchConditionsModel';
import { ResultStatus } from '../models/Dto/resultStatus';
import AxiosInstance from '../axiosInstance';

export default class CustomerSearchRepository implements ICustomerSearchRepository {
    private readonly axios = new AxiosInstance();

    public async searchCustomer(searchConditions: CustomerSearchConditions): Promise<CustomerSearchResultsDto> {
        let searchResults: CustomerSearchResultsDto = { resultStatus: ResultStatus.UnKnown, message: null, resultCount: 0, results: [] };

        const searchParams = new URLSearchParams(searchConditions);
        const response = await this.axios.teamworkApi().get('/v2/customer?' + searchParams);
        if (response.data.resultCount > 0) {
            searchResults = response.data;
        }

        return searchResults;
    }
}
