import _ from 'lodash';
import { SrAttributeType } from '../models/Dto/attributeTypeResultsDto';
import { AttributeTypeValuePair } from '../models/Dto/serviceRequestCommand';
import SrItemDetails from '../models/SrItemDetails';
import SrItemService from '../services/SrItemService';

const srItemService = new SrItemService();

export default class ExportForMojHelper {
    private requiredFields(srItemAttributes: SrAttributeType[], infringementExtension?: string) {
        let mojRequiredAttributes: AttributeTypeValuePair[] = [];
        const mojAttributeFields = ['Court of Hearing', 'Prosecuting Agency', 'Informant Name', 'Informant Address', 'Part Payment', 'Reissued Reminder'];

        _.forEach(mojAttributeFields, (mojAttributeField) => {
            _.find(srItemAttributes, (attribute) => {
                if (attribute.attributeDescription === mojAttributeField) {
                    const defaultValue = _.find(attribute.serviceRequestAttributeValues, (attributeValue) => {
                        return attributeValue.defaultValue === 'Y';
                    });
                    if (defaultValue?.attributeValue) {
                        mojRequiredAttributes.push({
                            srItemAttributeType: attribute.srItemAttributeType.toString(),
                            attributeValue: defaultValue.attributeValue,
                        });
                    } else if (attribute.attributeDescription === 'Reissued Reminder') {
                        mojRequiredAttributes.push({ srItemAttributeType: attribute.srItemAttributeType.toString(), attributeValue: 'N' });
                    } else if (attribute.attributeDescription === 'Prosecuting Agency' && infringementExtension && infringementExtension === '1014') {
                        mojRequiredAttributes.push({
                            srItemAttributeType: attribute.srItemAttributeType.toString(),
                            attributeValue: 'WNCR',
                        });
                    } else if (
                        attribute.attributeDescription === 'Prosecuting Agency' &&
                        infringementExtension &&
                        ['1012', '1013'].includes(infringementExtension)
                    ) {
                        mojRequiredAttributes.push({
                            srItemAttributeType: attribute.srItemAttributeType.toString(),
                            attributeValue: 'WNCB',
                        });
                    } else {
                        mojRequiredAttributes.push({
                            srItemAttributeType: attribute.srItemAttributeType.toString(),
                            attributeValue: attribute?.serviceRequestAttributeValues![0].attributeValue!,
                        });
                    }
                }
            });
        });
        return mojRequiredAttributes;
    }

    public async setupMojRequiredAttributes(
        srItemAttributes: SrAttributeType[],
        srItemSearchResults: SrItemDetails[],
        selectedSrItemNumber: string[],
        infringementExtension?: string
    ) {
        const selectedSrItem = _.filter(srItemSearchResults, (srItem) => {
            return selectedSrItemNumber.includes(srItem.srItem.toString());
        });

        _.forEach(selectedSrItem, async (srItem) => {
            const offenceDescriptionField = _.find(srItem.SrItemInfo, (srItemAttribute) => {
                return srItemAttribute.attributeDescription === 'Offence Description';
            });

            const totalFeeField = _.find(srItem.SrItemInfo, (srItemAttribute) => {
                return srItemAttribute.attributeDescription === 'Total Fee';
            });

            const offencePrecedentCodeField = _.find(srItem.SrItemInfo, (srItemAttribute) => {
                return srItemAttribute.attributeDescription === 'Offence Precedent Code';
            });

            const offenceDateField = _.find(srItem.SrItemInfo, (srItemAttribute) => {
                return srItemAttribute.attributeDescription === 'Offence Date';
            });

            let extraMojRequiredAttributes = this.requiredFields(srItemAttributes, infringementExtension);

            if (!offenceDescriptionField || !totalFeeField || !offencePrecedentCodeField || !offenceDateField) {
                const offenceDescriptionFullDetails = _.find(srItemAttributes, (attribute) => {
                    return attribute.attributeDescription === 'Offence Description';
                });

                const infringementSectionFullDetails = _.find(srItemAttributes, (attribute) => {
                    return attribute.attributeDescription === 'Infringement Section';
                });

                const selectedInfringementSection = _.find(srItem.SrItemInfo, (srItemAttribute) => {
                    return srItemAttribute.attributeDescription === 'Infringement Section';
                });

                const selectedInfringementSectionFullDetails = _.find(infringementSectionFullDetails?.serviceRequestAttributeValues, (attributeValue) => {
                    return attributeValue.attributeValue === selectedInfringementSection?.attributeValue;
                });

                if (offenceDescriptionFullDetails?.srItemAttributeType && selectedInfringementSectionFullDetails?.attributeValueDescr) {
                    extraMojRequiredAttributes.push({
                        srItemAttributeType: offenceDescriptionFullDetails.srItemAttributeType.toString(),
                        attributeValue: selectedInfringementSectionFullDetails.attributeValueDescr,
                    });
                }

                const totalFee = _.find(srItemAttributes, (attribute) => {
                    return attribute.attributeDescription === 'Total Fee';
                });

                if (totalFee && selectedInfringementSectionFullDetails?.feeAmount) {
                    extraMojRequiredAttributes.push({
                        srItemAttributeType: totalFee.srItemAttributeType.toString(),
                        attributeValue: parseFloat((Math.round(selectedInfringementSectionFullDetails.feeAmount * 100) / 100).toString()).toFixed(2),
                    });
                }

                const offencePrecedentCode = _.find(srItemAttributes, (attribute) => {
                    return attribute.attributeDescription === 'Offence Precedent Code';
                });

                if (offencePrecedentCode && selectedInfringementSectionFullDetails?.statsCode) {
                    extraMojRequiredAttributes.push({
                        srItemAttributeType: offencePrecedentCode.srItemAttributeType.toString(),
                        attributeValue: selectedInfringementSectionFullDetails.statsCode.toString(),
                    });
                }

                const offenceDateValue = _.find(srItem.SrItemInfo, (srItemAttribute) => {
                    return srItemAttribute.attributeDescription === 'Offence Date';
                });

                if (offenceDateValue?.attributeTypeId && offenceDateValue?.attributeValueDate) {
                    extraMojRequiredAttributes.push({
                        srItemAttributeType: offenceDateValue.attributeTypeId.toString(),
                        attributeValue: offenceDateValue.attributeValueDate.toString(),
                    });
                }
            }

            await srItemService.updateSrItem({ srItem: srItem.srItem, srAttributes: extraMojRequiredAttributes });
        });
    }
}
