const AuthConfig = {
    // (required)- Amazon Cognito Region
    region: process.env.REACT_APP_AwsRegion,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AwsCognitoUserPoolId,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.REACT_APP_AwsCognitoUserPoolWebClientId,

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    oauth: {
        domain: process.env.REACT_APP_AwsCognitoOauthDomain,
        scope: ['email', 'profile', 'openid', 'https://teamworkapi.digital-zone.wellingtoncity.cloud/api'],

        redirectSignIn: process.env.REACT_APP_AwsCognitoOauthSignIn ?? `https://${process.env.REACT_APP_VERCEL_URL}`,
        redirectSignOut: process.env.REACT_APP_AwsCognitoOauthSignOut ?? `https://${process.env.REACT_APP_VERCEL_URL}`,
        clientId: process.env.REACT_APP_AwsCognitoUserPoolWebClientId,

        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
};

export default AuthConfig;
