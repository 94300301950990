import { ChangeEvent, useState } from 'react';
import { Modal, Container, Button, FormGroup, Form, Row } from 'react-bootstrap';
import { DogSearchParameters } from '../../models/Dto/dogRegistrationSearchResults';
import DogSearchResultsTable from './DogSearchResultsTable';
import PopUpModal from '../PopUpModal';
import DogOwnerSrService from '../../services/DogOwnerSrService';
import { DogAndOwnerDetailsModel } from '../../models/DogAndOwnerModel';

interface DogSearchModalProps {
    dogSearchModalStatus: boolean;
    onHide(dog?: DogAndOwnerDetailsModel): void;
}

export default function DogSearchModal(props: DogSearchModalProps) {
    const dogOwnerSrService = new DogOwnerSrService();

    const [modalShow, setModalShow] = useState(false);
    const [messageHeader, setMessageHeader] = useState<string>('');

    const [dogOwnerName, setDogOwnerName] = useState<string>('');
    const [dogName, setDogName] = useState<string>('');
    const [dogOwnerAddress, setDogOwnerAddress] = useState<string>('');
    const [dogTagNumber, setDogTagNumber] = useState<string>('');

    const [searchingState, setSearchingState] = useState(false);
    const [dogSearchResult, setDogSearchResult] = useState<DogAndOwnerDetailsModel[] | null>(null);

    const updateInputValue = (el: ChangeEvent<HTMLInputElement>) => {
        switch (el.currentTarget.id) {
            case 'dogOwnerName':
                setDogOwnerName(el.currentTarget.value);
                break;
            case 'dogOwnerAddress':
                setDogOwnerAddress(el.currentTarget.value);
                break;
            case 'dogTagNumber':
                setDogTagNumber(el.currentTarget.value);
                break;
            case 'dogName':
                setDogName(el.currentTarget.value);
                break;
            default:
                break;
        }
    };

    const clearSearch = () => {
        setDogOwnerName('');
        setDogName('');
        setDogOwnerAddress('');
        setDogTagNumber('');
        setDogSearchResult(null);
    };

    const searchDog = async () => {
        let searchParams: DogSearchParameters = {};
        if (dogOwnerName) {
            const ownerName = { CustomerName: dogOwnerName };
            searchParams = { ...searchParams, ...ownerName };
        }
        if (dogOwnerAddress) {
            const ownerAddress = { Address: dogOwnerAddress };
            searchParams = { ...searchParams, ...ownerAddress };
        }
        if (dogName) {
            const dog = { DogName: dogName };
            searchParams = { ...searchParams, ...dog };
        }
        if (dogTagNumber) {
            const tagNumber = { DogRegistrationNo: dogTagNumber };
            searchParams = { ...searchParams, ...tagNumber };
        }

        if (Object.keys(searchParams).length !== 0) {
            setSearchingState(true);
            const results = await dogOwnerSrService.searchDogAndOwnerDetails(searchParams);
            setDogSearchResult(results);
            setSearchingState(false);
        } else {
            setModalShow(true);
            setMessageHeader('Please enter a search value.');
        }
    };

    return (
        <Modal show={props.dogSearchModalStatus} fullscreen={true} onHide={props.onHide} aria-labelledby='search customer modal' centered>
            <Container>
                <Modal.Header closeButton>
                    <Modal.Title>Search a Dog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <FormGroup className='wcc-form-group col-3'>
                            <Form.Label>Dog Owner</Form.Label>
                            <Form.Control id='dogOwnerName' type='text' value={dogOwnerName} onChange={updateInputValue} />
                        </FormGroup>
                        <FormGroup className='wcc-form-group col-3'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control id='dogOwnerAddress' type='text' value={dogOwnerAddress} onChange={updateInputValue} />
                        </FormGroup>
                        <FormGroup className='wcc-form-group col-3'>
                            <Form.Label>Dog Name</Form.Label>
                            <Form.Control id='dogName' type='text' value={dogName} onChange={updateInputValue} />
                        </FormGroup>
                        <FormGroup className='wcc-form-group col-3'>
                            <Form.Label>Tag Number</Form.Label>
                            <Form.Control id='dogTagNumber' type='number' value={dogTagNumber} onChange={updateInputValue} />
                        </FormGroup>
                    </Row>
                    <Row className='button-group'>
                        <Button variant='outline-primary' onClick={clearSearch}>
                            Clear
                        </Button>
                        <Button onClick={searchDog}>Search</Button>
                    </Row>
                    <DogSearchResultsTable
                        searchingState={searchingState}
                        dogInfo={dogSearchResult}
                        maxTableHeight={560}
                        emitDogInfo={(selectedDogInfo: DogAndOwnerDetailsModel) => {
                            props.onHide(selectedDogInfo);
                        }}
                    />
                </Modal.Body>
            </Container>
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={messageHeader} />
        </Modal>
    );
}
