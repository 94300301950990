import { Fragment, useEffect, useState } from 'react';
import { Container, Paper, CircularProgress } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SrItemDetails from '../../models/SrItemDetails';
import { Button } from 'react-bootstrap';

interface HeadCell {
    id: keyof SrItemDetails;
    label: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'SrNumber',
        label: 'SR Number',
    },
    {
        id: 'srItemType',
        label: 'SR Item Type',
    },
    {
        id: 'srStatusDescription',
        label: 'Status',
    },
    {
        id: 'description',
        label: 'Description',
    },
];

interface SrItemResultsTableProps {
    formContent: SrItemDetails[];
    isLoading: boolean;
}

export default function SrItemSearchResultsTable(props: SrItemResultsTableProps) {
    const [selectedSr, setSelected] = useState<SrItemDetails>();
    const rowsPerPage = 10;
    const [page, setPage] = useState(0);

    const navigate = useNavigate();

    const changePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const selectSrItem = (srItem: SrItemDetails) => {
        setSelected(srItem);
    };

    const isSelected = (srItemNumber: number) => {
        if (selectedSr) {
            return selectedSr.srItem.toString().indexOf(srItemNumber.toString()) !== -1;
        }
    };

    const viewSrItemDetails = (srItemDetails: SrItemDetails) => {
        navigate('/infringement/' + srItemDetails.srItemTypeId + '/' + srItemDetails.srItem, {
            state: { srType: srItemDetails.srItemTypeId, selectedSr: srItemDetails, selectedSrItem: srItemDetails },
        });
    };

    useEffect(() => {
        setPage(0);
    }, [props.isLoading]);

    return (
        <Fragment>
            <Paper style={{ marginBottom: '2rem' }}>
                <TableContainer>
                    {props.isLoading ? (
                        <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />
                    ) : (
                        <Table sx={{ minWidth: 750 }} aria-labelledby='infringement search results'>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell key={headCell.id}>
                                            <TableSortLabel>{headCell.label}</TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.formContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    const isItemSelected = isSelected(row.srItem);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => selectSrItem(row)}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.srItem}
                                            selected={isItemSelected}
                                        >
                                            <TableCell>
                                                <Button variant='link' onClick={() => viewSrItemDetails(row)}>
                                                    {row.SrNumber}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='link' onClick={() => viewSrItemDetails(row)}>
                                                    {row.srItemType}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='link' onClick={() => viewSrItemDetails(row)}>
                                                    {row.srStatusDescription}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='link' onClick={() => viewSrItemDetails(row)}>
                                                    {row.Description}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    )}
                    {props.formContent.length === 0 && !props.isLoading ? (
                        <Container className='empty-search-results'>
                            <h3>No infringement matches above criteria</h3>
                        </Container>
                    ) : null}
                </TableContainer>
                {props.isLoading || props.formContent.length === 0 ? null : (
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component='div'
                        count={props.formContent.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={changePage}
                    />
                )}
            </Paper>
        </Fragment>
    );
}
