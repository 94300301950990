import { ChangeEvent, Fragment, useState } from 'react';
import { Button, FormGroup, Form, Row } from 'react-bootstrap';
import { DogSearchParameters } from '../../models/Dto/dogRegistrationSearchResults';
import DogSearchResultsTable from '../CreateInfringementPage/DogSearchResultsTable';
import DogOwnerSrService from '../../services/DogOwnerSrService';
import PopUpModal from '../PopUpModal';
import { DogAndOwnerDetailsModel } from '../../models/DogAndOwnerModel';
import { useNavigate } from 'react-router-dom';
import SearchSrService from '../../services/SearchSrService';
import { SrItemType } from '../../models/SrType';

const searchSrService = new SearchSrService();

export default function DogSearchSection() {
    const dogOwnerSrService = new DogOwnerSrService();

    const [isLoading, setIsLoading] = useState(false);
    const navigator = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [messageHeader, setMessageHeader] = useState<string>('');
    const [messageBody, setMessageBody] = useState<string>('');

    const [dogOwnerName, setDogOwnerName] = useState<string>('');
    const [dogName, setDogName] = useState<string>('');
    const [dogOwnerAddress, setDogOwnerAddress] = useState<string>('');
    const [dogTagNumber, setDogTagNumber] = useState<string>('');

    const [searchingState, setSearchingState] = useState(false);
    const [dogSearchResult, setDogSearchResult] = useState<DogAndOwnerDetailsModel[] | null>(null);

    const updateInputValue = (el: ChangeEvent<HTMLInputElement>) => {
        switch (el.currentTarget.id) {
            case 'dogOwnerName':
                setDogOwnerName(el.currentTarget.value);
                break;
            case 'dogOwnerAddress':
                setDogOwnerAddress(el.currentTarget.value);
                break;
            case 'dogTagNumber':
                setDogTagNumber(el.currentTarget.value);
                break;
            case 'dogName':
                setDogName(el.currentTarget.value);
                break;
            default:
                break;
        }
    };

    const clearSearch = () => {
        setDogOwnerName('');
        setDogName('');
        setDogOwnerAddress('');
        setDogTagNumber('');
        setDogSearchResult(null);
    };

    const searchDog = async () => {
        let searchParams: DogSearchParameters = {};
        if (dogOwnerName) {
            const ownerName = { CustomerName: dogOwnerName };
            searchParams = { ...searchParams, ...ownerName };
        }
        if (dogOwnerAddress) {
            const ownerAddress = { Address: dogOwnerAddress };
            searchParams = { ...searchParams, ...ownerAddress };
        }
        if (dogName) {
            const dog = { DogName: dogName };
            searchParams = { ...searchParams, ...dog };
        }
        if (dogTagNumber) {
            const tagNumber = { DogRegistrationNo: dogTagNumber };
            searchParams = { ...searchParams, ...tagNumber };
        }

        if (Object.keys(searchParams).length !== 0) {
            setSearchingState(true);
            const results = await dogOwnerSrService.searchDogAndOwnerDetails(searchParams);
            setDogSearchResult(results);
            setSearchingState(false);
            setTimeout(() => {
                window.scrollTo(0, 540);
            }, 50);
        } else {
            setModalShow(true);
            setMessageHeader('Please enter a search value.');
            setMessageBody('');
        }
    };

    const createSrItemByDogInfo = async (dogAndOwnerDetails: DogAndOwnerDetailsModel) => {
        setIsLoading(true);
        setModalShow(true);
        const dogOwnerSr = await searchSrService.searchServiceRequests({ SrCode: dogAndOwnerDetails.owner.ownerSr.toString() });
        const reRegDogOwner = await dogOwnerSrService.getDogOwnerInfo(dogAndOwnerDetails.owner.ownerSr);
        setIsLoading(false);
        setModalShow(false);
        navigator('/create-service-request-item', {
            state: { srType: +SrItemType.DogInfringement, selectedSr: dogOwnerSr[0], reRegDogOwner: reRegDogOwner, dogAndOwnerDetails: dogAndOwnerDetails },
        });
    };

    return (
        <Fragment>
            <Row>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Dog Owner</Form.Label>
                    <Form.Control id='dogOwnerName' type='text' value={dogOwnerName} onChange={updateInputValue} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control id='dogOwnerAddress' type='text' value={dogOwnerAddress} onChange={updateInputValue} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Dog Name</Form.Label>
                    <Form.Control id='dogName' type='text' value={dogName} onChange={updateInputValue} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Tag Number</Form.Label>
                    <Form.Control id='dogTagNumber' type='number' value={dogTagNumber} onChange={updateInputValue} />
                </FormGroup>
            </Row>
            <Row className='button-group'>
                <Button variant='outline-primary' onClick={clearSearch}>
                    Clear
                </Button>
                <Button onClick={searchDog}>Search</Button>
            </Row>
            <hr style={{ color: '#dee2e6' }} />
            <DogSearchResultsTable
                searchingState={searchingState}
                dogInfo={dogSearchResult}
                emitDogInfo={(selectedDogInfo: DogAndOwnerDetailsModel) => {
                    createSrItemByDogInfo(selectedDogInfo);
                }}
            />
            <PopUpModal
                modalStatus={modalShow}
                isUpdating={isLoading}
                onHide={() => setModalShow(false)}
                messageHeader={messageHeader}
                messageBody={messageBody}
            />
        </Fragment>
    );
}
