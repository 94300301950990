export default interface ServiceRequestCommand {
    srType: SrType; // included
    comment?: string; // included
    locationStreet?: string; // included
    locationComment?: string; // included
    locationNumber?: string; // included
    srItemTypeId?: number; // included
    description?: string; // included
    purpose?: string;
    itemComment?: string;
    perorgCode?: number; // included
    addressCode?: number;
    attention?: string;
    oldSr?: number;
    srRelationType?: number;
    srTypeExtension?: number; // included
    description1?: string;
    comment1?: string;
    srAttributes?: AttributeTypeValuePair[];
}

export interface AttributeTypeValuePair {
    srItemAttributeType: string;
    attributeValue?: string;
}

export enum SrType {
    NotSet = 'NotSet',
    BuildingConsent = 'BuildingConsent',
    DogRegistration = 'Dog',
    DogOwner = 'DogOWNR',
    LIM = 'LIM',
    LiquorLicenceClub = 'LLClub',
    LiquorLicenceON = 'LLOn',
    LiquorLicenceOFF = 'LLOff',
    LiquorLicenceSpecial = 'LLSpecial',
    LiquorLicenceTemporaryAuthority = 'LLTemp',
    LiquorLicenceManagersCertificate = 'LLManager',
    Infringement = 'Infringement',
    Unknown = 'Unknown',
}
