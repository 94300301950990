import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, CircularProgress } from '@mui/material';
import { SrAttributeType } from '../../models/Dto/attributeTypeResultsDto';

interface HeadCell {
    id: keyof SrAttributeType;
    label: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'srItemAttributeType',
        label: 'Form Field ID',
    },
    {
        id: 'attributeType',
        label: 'Form Field Label',
    },
    {
        id: 'attributeMandatory',
        label: 'Mandatory Field',
    },
    {
        id: 'attributeValidation',
        label: 'Input Type',
    },
    {
        id: 'attributeSequence',
        label: 'Form Field Sequence',
    },
];

interface SrResultsTableProps {
    formContent: SrAttributeType[];
    isLoading: boolean;
}

export default function SrItemFormMaintenanceTable(props: SrResultsTableProps) {
    const [selectedSr, setSelected] = React.useState<number>(0);

    const handleClick = (event: React.MouseEvent<unknown>, srNumber: number) => {
        setSelected(srNumber);
    };

    const isSelected = (srNumber: number) => selectedSr?.toString().indexOf(srNumber.toString()) !== -1;

    return (
        <Paper>
            <TableContainer>
                {props.isLoading ? (
                    <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />
                ) : (
                    <Table sx={{ minWidth: 750 }} aria-labelledby='sr search results'>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell key={headCell.id}>
                                        <TableSortLabel>{headCell.label}</TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.formContent.map((row, index) => {
                                const isItemSelected = isSelected(row.srItemAttributeType!);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.srItemAttributeType!)}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.srItemAttributeType}
                                        selected={isItemSelected}
                                    >
                                        <TableCell component='th' id={labelId} scope='row'>
                                            {row.srItemAttributeType}
                                        </TableCell>
                                        <TableCell>{row.attributeDescription}</TableCell>
                                        <TableCell>{row.attributeMandatory}</TableCell>
                                        <TableCell>{row.attributeValidation === 'CHAR' ? 'TEXT' : row.attributeValidation}</TableCell>
                                        <TableCell>{row.attributeSequence}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Paper>
    );
}
