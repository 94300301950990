import { useState, MouseEvent, Fragment, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Button, Container, Row } from 'react-bootstrap';
import CustomerDetails from '../../models/CustomerDetails';
import CircularProgress from '@mui/material/CircularProgress';

interface HeadCell {
    id: keyof CustomerDetails;
    label: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'CustomerName',
        label: 'Customer Name',
    },
    {
        id: 'NZUrban',
        label: 'NZ Urban',
    },
    {
        id: 'Phone',
        label: 'Phone',
    },
    {
        id: 'DateOfBirth',
        label: 'Date of Birth',
    },
];

interface CustomerSearchResultsTableProps {
    searchingState: boolean;
    customers: CustomerDetails[] | null;
    emitCustomer(customer: CustomerDetails): void;
}

export default function CustomerSearchResultsTable(props: CustomerSearchResultsTableProps) {
    const [selectedCustomer, setSelected] = useState<CustomerDetails>(new CustomerDetails());
    const rowsPerPage = 10;
    const [page, setPage] = useState(0);

    const handleClick = (event: MouseEvent<unknown>, row: CustomerDetails) => {
        setSelected(row);
    };

    const isSelected = (perorgCode: number) => {
        return selectedCustomer.PerorgCode === perorgCode;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const selectCustomer = async () => {
        props.emitCustomer(selectedCustomer);
    };

    useEffect(() => {
        setPage(0);
    }, [props.searchingState]);

    const resultTable = () => {
        if (props.searchingState) {
            return <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />;
        } else if (!props.searchingState && props.customers !== null && props.customers.length === 0) {
            return (
                <Container className='empty-search-results'>
                    <h3>No result found.</h3>
                    <h5>Seems we don't have this person's info in WCC's database</h5>
                    <h5>
                        Please use the&nbsp;
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://forms.office.com/Pages/ResponsePage.aspx?id=B62H8XBPcU2agN-wGRV4ruwqk19hicdJgATnQOXIiEVUOFdHWVJUNzRaODJOWVNGRDdBSlU2SjJEVS4u'
                        >
                            Power Automate Form
                        </a>
                        &nbsp;to log the infringement.
                    </h5>
                </Container>
            );
        } else if (props.customers !== null) {
            return (
                <Table sx={{ minWidth: 750 }} stickyHeader aria-labelledby='customer search results'>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id}>
                                    <TableSortLabel>{headCell.label}</TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const isItemSelected = isSelected(row.PerorgCode!);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.PerorgCode}
                                    selected={isItemSelected}
                                >
                                    <TableCell component='th' id={labelId} scope='row'>
                                        {row.CustomerName}
                                    </TableCell>
                                    <TableCell>{row.NZUrban}</TableCell>
                                    <TableCell>{row.Phone}</TableCell>
                                    <TableCell>{row.DateOfBirth}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            );
        }
    };

    return (
        <Fragment>
            <Paper style={{ marginTop: '3rem' }}>
                <TableContainer sx={{ maxHeight: 480 }}>{resultTable()}</TableContainer>
                {!props.searchingState && props.customers !== null && props.customers.length > 0 ? (
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component='div'
                        count={props.customers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                ) : null}
            </Paper>
            {props.customers !== null && props.customers.length > 0 ? (
                <Row className='button-group'>
                    <Button onClick={selectCustomer}>Select</Button>
                </Row>
            ) : null}
        </Fragment>
    );
}
