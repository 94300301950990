import ISearchSrService from './Impl/ISearchSrService';
import SearchStateRepository from '../repositories/SearchNoticeRepository';
import SrResult from '../models/SrResult';
import { SearchSrConditions } from '../models/searchConditionsModel';

export default class SearchNoticeService implements ISearchSrService {
    private readonly searchStateRepo: SearchStateRepository = new SearchStateRepository();

    public async searchServiceRequests(searchConditions: SearchSrConditions, selectedSrType?: string): Promise<SrResult[]> {
        const response = await this.searchStateRepo.GetSrResults(searchConditions);

        let srResults: SrResult[] = [];

        if (response.resultCount === 0) {
            return srResults;
        } else {
            response.results!.forEach((result) => {
                let srItem: SrResult = { SrNumber: 0 };
                srItem.SrNumber = result.srCode;
                srItem.SrType = result.typeDescription;
                srItem.CustomerName = result.customerName;
                srItem.Address = result.fullAddress;
                srItem.PostalAddress = result.postalAddress;
                srItem.Description = result.description;
                srItem.LocationDescription = result.locationDescription;
                srResults.push(srItem);
            });
        }
        return srResults;
    }
}
