import { DogAndOwnerSearchDto } from '../models/Dto/dogRegistrationSearchResults';
import { SrAttributeValue } from '../models/Dto/attributeTypeResultsDto';
import DogColourCodeUtils from './dogColourCodeUtils';
import DogInfoModel from '../models/DogInfoModel';

const dogColourCodeUtils = new DogColourCodeUtils();
export default class FormFieldGeneratorUtils {
    public getDefaultInputFieldValue(attributeDescription: string | undefined, dogInfo?: DogInfoModel) {
        if (dogInfo && dogInfo.dogSrNumber?.value !== '0') {
            switch (attributeDescription) {
                case 'Dog SR Number':
                    return dogInfo.dogSrNumber?.value;
                case 'Dog Registration No':
                    return dogInfo.registrationNo?.value;
                case 'Dog Name':
                    return dogInfo.dogName?.value;
                default:
                    return '';
            }
        }
    }

    public getDefaultDropdownValue(attribuiteValues: SrAttributeValue[], formId: number, dogInfo: DogAndOwnerSearchDto) {
        const dropdownDefaultValue = attribuiteValues.find((value) => value.defaultValue === 'Y' && value.attributeValue !== undefined);
        if (dropdownDefaultValue) {
            return dropdownDefaultValue?.attributeValue;
        } else if (formId === 5044593) {
            return dogInfo.dogGender?.toLocaleLowerCase();
        } else if (formId === 5044641) {
            return dogInfo.dogBreed1;
        } else if (formId === 5044643) {
            return dogColourCodeUtils.getCorrectColourCode(dogInfo.dogColour1);
        } else if (formId === 5044644) {
            return dogColourCodeUtils.getCorrectColourCode(dogInfo.dogColour2);
        }
    }

    public inputType(type: string | undefined) {
        switch (type) {
            case 'DATE':
                return 'date';
            case 'NUMBER':
                return 'number';
            default:
                return 'text';
        }
    }
}
