import { FormGroup, Row, Col, Form } from 'react-bootstrap';
import SrResult from '../../models/SrResult';

interface SrInfoProps {
    selectedSr: SrResult;
}

export default function ServiceRequestInfo(props: SrInfoProps) {
    const { SrNumber, Description, Address, LocationDescription, SrType } = props.selectedSr;

    return (
        <Row>
            <h5 style={{ fontWeight: '700', marginBottom: '1rem' }}>Service Request Details</h5>
            <Col>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>SR Number</Form.Label>
                    <Form.Control type='text' defaultValue={SrNumber} disabled={true} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Service Request Type</Form.Label>
                    <Form.Select aria-label='Select an Act section' disabled={true} defaultValue={SrType}>
                        <option key='21' value='Dog Owner'>
                            Dog Owner
                        </option>
                        <option key='5016034' value='Infringement'>
                            General Infringement
                        </option>
                    </Form.Select>
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>SR Item Description</Form.Label>
                    <Form.Control as='textarea' defaultValue={Description} rows={4} disabled={true} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control type='text' defaultValue={Address} disabled={true} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Location Comment</Form.Label>
                    <Form.Control as='textarea' defaultValue={LocationDescription} rows={4} disabled={true} />
                </FormGroup>
            </Col>
        </Row>
    );
}
