import { DogColours } from '../models/Dto/dogColours';

export default class DogColourCodeUtils {
    public getCorrectColourCode(code: string | undefined): string {
        switch (code) {
            case 'TAN':
                return DogColours.TAN;
            case 'BRO':
                return DogColours.BRO;
            case 'BLK':
                return DogColours.BLK;
            case 'BLU':
                return DogColours.BLU;
            case 'GRY':
                return DogColours.GRY;
            case 'TRI':
                return DogColours.TRI;
            case 'GLD':
                return DogColours.GLD;
            case 'BDL':
                return DogColours.BDL;
            case 'RED':
                return DogColours.RED;
            case 'WHI':
                return DogColours.WHI;
            case 'ONG':
                return DogColours.ONG;
            default:
                return '';
        }
    }
}
