import { useEffect, useState, ChangeEvent } from 'react';
import { Form, FormGroup, Container } from 'react-bootstrap';
import DogInfringementAdditionalInfo from '../../models/DogInfringementAdditionalInfo';

interface AdditionalInfoProps {
    formStatus: boolean;
    emitAdditionalInfo(info: DogInfringementAdditionalInfo): void;
}

export default function DogOwnerInfringementAdditionalFields(props: AdditionalInfoProps) {
    const [dogFeatures, setDogFeatures] = useState('');
    const [dogOwnerFeatures, setDogOwnerFeatures] = useState('');
    const [hasVehicleInvolved, setHasVehicleInvolved] = useState('no');
    const [vehicleDetails, setVehicleDetails] = useState('');
    const [dogOwnerExplanation, setDogOwnerExplanation] = useState('');

    const updateInputField = (el: ChangeEvent<HTMLInputElement>) => {
        switch (el.target.id) {
            case 'DogFeatures':
                setDogFeatures(el.target.value);
                break;
            case 'DogOwnerFeatures':
                setDogOwnerFeatures(el.target.value);
                break;
            case 'VehicleDetails':
                setVehicleDetails(el.target.value);
                break;
            case 'OwnerExplanation':
                setDogOwnerExplanation(el.target.value);
                break;
            default:
                break;
        }
    };

    const updateSelectionField = (el: ChangeEvent<HTMLSelectElement>) => {
        setHasVehicleInvolved(el.target.value);
    };

    const emitAdditionalInfo = () => {
        let additionalInfo = {};
        if (dogFeatures) {
            additionalInfo = { ...additionalInfo, ...{ dogFeatures: dogFeatures } };
        }
        if (dogOwnerFeatures) {
            additionalInfo = { ...additionalInfo, ...{ dogOwnerFeatures: dogOwnerFeatures } };
        }
        if (hasVehicleInvolved === 'yes') {
            additionalInfo = { ...additionalInfo, ...{ hasVehicleInvolved: hasVehicleInvolved } };
            additionalInfo = { ...additionalInfo, ...{ vehicleDetails: vehicleDetails } };
        }
        if (dogOwnerExplanation) {
            additionalInfo = { ...additionalInfo, ...{ dogOwnerExplanation: dogOwnerExplanation } };
        }
        props.emitAdditionalInfo(additionalInfo);
    };

    useEffect(() => {
        emitAdditionalInfo();
    }, [dogFeatures, dogOwnerFeatures, hasVehicleInvolved, vehicleDetails, dogOwnerExplanation]);

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <hr />
            <h5 style={{ fontWeight: '700', marginBottom: '1rem' }}>Additional Info</h5>
            <FormGroup className='wcc-form-group'>
                <Form.Label>Distinguishing features of dog</Form.Label>
                <Form.Control id='DogFeatures' as='textarea' value={dogFeatures} onChange={updateInputField} rows={4} disabled={props.formStatus} />
            </FormGroup>
            <FormGroup className='wcc-form-group'>
                <Form.Label>Distinguishing features of person in charge</Form.Label>
                <Form.Control id='DogOwnerFeatures' as='textarea' value={dogOwnerFeatures} onChange={updateInputField} rows={4} disabled={props.formStatus} />
            </FormGroup>

            <FormGroup className='wcc-form-group'>
                <Form.Label>Has Vehicle Involved</Form.Label>
                <Form.Select aria-label='Select an Option' value={hasVehicleInvolved} onChange={updateSelectionField} disabled={props.formStatus}>
                    <option value='no'>No</option>
                    <option value='yes'>Yes</option>
                </Form.Select>
            </FormGroup>
            <FormGroup className='wcc-form-group'>
                <Form.Label>Vehicle Details</Form.Label>
                <Form.Control
                    id='VehicleDetails'
                    as='textarea'
                    value={vehicleDetails}
                    onChange={updateInputField}
                    rows={4}
                    disabled={hasVehicleInvolved === 'no' || props.formStatus}
                />
            </FormGroup>

            <FormGroup className='wcc-form-group'>
                <Form.Label>Owner's explanation</Form.Label>
                <Form.Control
                    id='OwnerExplanation'
                    as='textarea'
                    value={dogOwnerExplanation}
                    onChange={updateInputField}
                    rows={4}
                    disabled={props.formStatus}
                />
            </FormGroup>
        </Container>
    );
}
