import { FormGroup, Row, Col, Form } from 'react-bootstrap';
import SrItemDetails from '../../models/SrItemDetails';

interface SrItemDetailsProps {
    selectedSrItem: SrItemDetails;
    previewMode: boolean;
}

export default function SrItemDetailsSection(props: SrItemDetailsProps) {
    return (
        <Row>
            <h5 style={{ fontWeight: '700', marginBottom: '1rem' }}>SR Item Info</h5>
            <Col>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>SR Number</Form.Label>
                    <Form.Control as='input' value={props.selectedSrItem.SrNumber} disabled={props.previewMode} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Infringement Type</Form.Label>
                    <Form.Control as='input' value={props.selectedSrItem.srItemType} disabled={props.previewMode} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Status</Form.Label>
                    <Form.Control type='text' value={props.selectedSrItem.srStatusDescription} disabled={props.previewMode} />
                </FormGroup>
            </Col>
        </Row>
    );
}
