import { Fragment, useEffect, useState } from 'react';
import { Row, FormGroup, Form } from 'react-bootstrap';
import CustomerDetails from '../../models/CustomerDetails';

interface AssociatedCustomerInfoProps {
    customer: CustomerDetails;
}

export default function AssociatedCustomerInfo(props: AssociatedCustomerInfoProps) {
    const [customerName, setCustomerName] = useState<string | undefined>('');
    const [phone, setContactNumber] = useState<string | undefined>('');
    const [nzUrban, setAddress] = useState<string | undefined>('');
    const [cellular, setCellular] = useState<string | undefined>('');
    const [email, setEmail] = useState<string | undefined>('');
    const [dateOfBirth, setDateOfBirth] = useState('');

    useEffect(() => {
        setCustomerName(props.customer.CustomerName!);
        setContactNumber(props.customer.Phone!);
        setAddress(props.customer.NZUrban!);
        setCellular(props.customer.Cellular!);
        setEmail(props.customer.EMail!);
        setDateOfBirth(props.customer.DateOfBirth);
    });

    return (
        <Fragment>
            <h4>Associated Customer</h4>
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control type='text' value={customerName} disabled />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>NZ Urban Address</Form.Label>
                    <Form.Control type='text' value={nzUrban} disabled />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type='text' value={dateOfBirth} disabled />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control type='text' value={phone} disabled />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Cellular</Form.Label>
                    <Form.Control type='text' value={cellular} disabled />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control type='text' value={email} disabled />
                </FormGroup>
            </Row>
        </Fragment>
    );
}
