import { useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import SearchExistingSr from '../components/CreateInfringementPage/SearchExistingSr';
import { SearchSrConditions } from '../models/searchConditionsModel';
import SrResult from '../models/SrResult';
import SearchNoticeService from '../services/SearchSrService';
import SrResultsTable from '../components/CreateInfringementPage/SrResultsTable';
import CreateServiceRequest from './CreateServiceRequest';
import PopUpModal from '../components/PopUpModal';
import DogSearchSection from '../components/CreateServiceRequest/DogSearchSection';

const searchService = new SearchNoticeService();

export default function SearchServiceRequest() {
    const [activeTab, setActiveTab] = useState<string>('SearchDogInfo');
    const [searchingState, setSearchingState] = useState(false);
    const [srResults, setSrResults] = useState<SrResult[] | null>(null);

    const [modalShow, setModalShow] = useState(false);
    const [messageHeader, setMessageHeader] = useState<string>('');
    const [messageBody, setMessageBody] = useState<string>();

    const searchSr = async (searchConditions: SearchSrConditions) => {
        try {
            setSearchingState(true);
            const searchResults = await searchService.searchServiceRequests(searchConditions);
            setSrResults(searchResults);
            if (searchResults.length > 0) {
                setTimeout(() => {
                    window.scrollTo(0, 500);
                }, 50);
            }
        } catch {
            setModalShow(true);
            setMessageHeader('Server side error');
            setMessageBody('Please report to the development team with this issue.');
        } finally {
            setSearchingState(false);
        }
    };

    const clearSrResults = () => {
        setSrResults(null);
    };

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <div className='page-header'>
                <h4 className='header-title'>Create Infringement</h4>
            </div>
            <hr />
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k!)}>
                <Tab eventKey='SearchDogInfo' title='Search Dogs'>
                    <div className='search-service-request'>
                        <DogSearchSection />
                    </div>
                </Tab>
                <Tab eventKey='SearchSr' title='Search SR'>
                    <SearchExistingSr
                        searchButtonClicked={(searchConditions: SearchSrConditions) => {
                            searchSr(searchConditions);
                        }}
                        clearSearchResults={clearSrResults}
                    />
                    <hr style={{ color: '#dee2e6' }} />
                    <SrResultsTable searchingState={searchingState} srResults={srResults} />
                </Tab>
                <Tab eventKey='CreateSr' title='Create SR'>
                    <CreateServiceRequest />
                </Tab>
            </Tabs>
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={messageHeader} messageBody={messageBody} />
        </Container>
    );
}
