import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <Container fluid='sm' style={{ flexGrow: 2, marginTop: '4rem' }}>
            <h2>Page not found</h2>
            <Link to='/'>Return </Link>
        </Container>
    );
}
