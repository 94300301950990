import { Button, Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import SrItemDetailsSection from '../components/SearchPage/SrItemDetailsSection';
import GenericInfringementForm from '../components/CreateInfringementPage/GenericInfringementForm';
import SrResult from '../models/SrResult';
import { Fragment, useContext, useEffect, useState } from 'react';
import SrItemDetails from '../models/SrItemDetails';
import { AttributeTypeValuePair } from '../models/Dto/serviceRequestCommand';
import SrItemService from '../services/SrItemService';
import PopUpModal from '../components/PopUpModal';
import { SrItemAttributesCommand, SrItemUpdateCommand } from '../models/SrItemAttributesCommand';
import SrItemStatusCommand, { DogOwnerSrStatusType } from '../models/Dto/srItemStatusCommand';
import { AuthContext } from '../auth/AuthContext';
import { FunctionAccess, RenderIfHasAccess } from '../auth/RoleAccess';

const srItemService = new SrItemService();

interface locationState {
    srType: number;
    selectedSr: SrResult;
    selectedSrItem: SrItemDetails;
}

export default function InfringementDetails() {
    const location = useLocation();
    const locationState = location.state as locationState;

    const authInfo = useContext(AuthContext);

    const [isUpdating, setIsUpdating] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalHeader, setModalHeader] = useState('');
    const [modalMessge, setModalMessage] = useState('');
    const [hasConfirmButton, setHasConfirmButton] = useState(false);

    const [srItemDetails, setSrItemDetails] = useState<SrItemDetails>();

    const [formFieldStatus, setFormFieldStatus] = useState(true);
    const [updatedSrItem, setUpdatedSrItem] = useState<AttributeTypeValuePair[]>([]);
    const [updatedSrItemGeneralDetails, setUpdatedSrItemGeneralDetails] = useState<SrItemUpdateCommand>();

    const updateInfringementDetails = async () => {
        const srItem: SrItemAttributesCommand = {
            srItem: locationState.selectedSrItem.srItem,
            srAttributes: updatedSrItem,
        };
        try {
            setModalShow(true);
            setIsUpdating(true);
            await srItemService.updateSrItem(srItem);
            setModalHeader('Infringement has been udpated.');
            setModalMessage('');
            setFormFieldStatus(true);
        } catch (error: any) {
            setModalShow(true);
            setModalHeader('Fail to update infringement');
            setModalMessage(error.response.data.errors.srAttributes[0]);
        }
        if (updatedSrItemGeneralDetails) {
            try {
                setModalShow(true);
                setIsUpdating(true);
                await srItemService.updateSrItemGeneralDetails(updatedSrItemGeneralDetails);
                setModalHeader('Infringement has been udpated.');
                setModalMessage('');
                setFormFieldStatus(true);
            } catch (error: any) {
                setModalShow(true);
                setModalHeader('Fail to update infringement description and comment');
                setModalMessage(error.response.data.errors.srAttributes[0]);
            }
        }
        setHasConfirmButton(false);
        setIsUpdating(false);
    };

    const showPopupMessage = () => {
        setModalShow(true);
        setModalHeader('Withdraw Infringement');
        setModalMessage('Are you sure you want to withdraw this infringement?');
        setHasConfirmButton(true);
    };

    const withdrawInfringement = async () => {
        setModalShow(false);
        setModalHeader('');
        setModalMessage('');
        setHasConfirmButton(false);
        setIsUpdating(true);
        const srItemStatusCommand: SrItemStatusCommand = {
            srItem: locationState.selectedSrItem.srItem,
            srStatusType: DogOwnerSrStatusType.WithdrawnByWCC,
            srStatusComment: 'Withdrawn by ' + authInfo.userEmail,
        };
        try {
            setModalShow(true);
            setIsUpdating(true);
            await srItemService.updateSrItemStatus(srItemStatusCommand);
            setIsUpdating(false);
            setModalHeader('Infringement has been withdrawn');
            if (srItemDetails) {
                let updatedSrItemDetails = srItemDetails;
                updatedSrItemDetails.srStatusDescription = 'Withdrawn by WCC';
                setSrItemDetails(updatedSrItemDetails);
            }
        } catch (error: any) {
            setModalShow(true);
            setIsUpdating(false);
            setModalHeader('Fail to cancel infringement');
            setModalMessage(error.response.data.errors.srAttributes[0]);
        }
    };

    const canWithdraw = () => {
        const notEditableStatus = ['Fee Paid', 'Completed', 'Cancelled', 'Court Payment Received', 'Withdrawn by WCC'];
        if (locationState.selectedSrItem.srStatusDescription && notEditableStatus.includes(locationState.selectedSrItem.srStatusDescription)) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setSrItemDetails(locationState.selectedSrItem);
    }, [locationState.selectedSrItem]);

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <div className='page-header'>
                <h4 className='header-title'>Infringement Details</h4>
                {formFieldStatus ? (
                    <Fragment>
                        <RenderIfHasAccess requiresFunction={FunctionAccess.Withdraw}>
                            {canWithdraw() ? <Button onClick={() => showPopupMessage()}>Withdraw</Button> : null}
                        </RenderIfHasAccess>
                        <RenderIfHasAccess requiresFunction={FunctionAccess.Edit}>
                            <Button onClick={() => setFormFieldStatus(false)}>Edit</Button>
                        </RenderIfHasAccess>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button
                            onClick={() => {
                                updateInfringementDetails();
                            }}
                        >
                            Update
                        </Button>
                        <Button variant='outline-primary' onClick={() => setFormFieldStatus(true)}>
                            Cancel
                        </Button>
                    </Fragment>
                )}
            </div>
            <hr />
            <Row style={{ marginTop: '2rem' }}>
                <Col sm={4}>{srItemDetails ? <SrItemDetailsSection selectedSrItem={srItemDetails} previewMode={true} /> : null}</Col>
                <Col sm={8}>
                    <GenericInfringementForm
                        selectedSr={locationState.selectedSr}
                        selectedSrItem={locationState.selectedSrItem}
                        srType={locationState.srType}
                        previewMode={formFieldStatus}
                        emitUpdatedSrItem={(srItem: AttributeTypeValuePair[]) => {
                            setUpdatedSrItem(srItem);
                        }}
                        emitGeneralDetails={(generalDetails: SrItemUpdateCommand) => {
                            setUpdatedSrItemGeneralDetails(generalDetails);
                        }}
                    />
                </Col>
            </Row>
            {formFieldStatus ? (
                <Fragment>
                    <hr />
                    <Row className='button-group'>
                        <RenderIfHasAccess requiresFunction={FunctionAccess.Withdraw}>
                            {canWithdraw() ? <Button onClick={() => showPopupMessage()}>Withdraw</Button> : null}
                        </RenderIfHasAccess>
                        <RenderIfHasAccess requiresFunction={FunctionAccess.Edit}>
                            <Button onClick={() => setFormFieldStatus(false)}>Edit</Button>
                        </RenderIfHasAccess>
                    </Row>
                </Fragment>
            ) : (
                <Fragment>
                    <hr />
                    <Row className='button-group'>
                        <Button
                            onClick={() => {
                                updateInfringementDetails();
                            }}
                        >
                            Update
                        </Button>
                        <Button variant='outline-primary' onClick={() => setFormFieldStatus(true)}>
                            Cancel
                        </Button>
                    </Row>
                </Fragment>
            )}
            <PopUpModal
                modalStatus={modalShow}
                onHide={() => setModalShow(false)}
                hasConfirmButton={hasConfirmButton}
                onConfirm={() => withdrawInfringement()}
                isUpdating={isUpdating}
                messageHeader={modalHeader}
                messageBody={modalMessge}
            />
        </Container>
    );
}
